import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import API_DOMAIN from "../config/config";
import TableUI from "../components/Table";
import MobileView from "../components/MobileView";
import Spinner from "react-bootstrap/Spinner";

const UserTablehead = [
  "No",
  "Company Name",
  " Mobile Number",
  " Place",
  "Action",
];
const Company = () => {
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    console.log("Component mounted");
    fetchData();
  }, []);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/company.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listCompany",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);

      setLoading(false);

      if (responseData.head.code === 200) {
        setUserData(responseData.body.companies || []); // Use the correct path
      } else {
        throw new Error(responseData.head.msg || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  console.log(userData);

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="12" md="12" xs="12">
            <div className="page-nav py-3">
              <span class="nav-list"> Company Details</span>
            </div>
          </Col>
          <Col lg="12" md="12" xs="12">
            <div className="py-1">
              {/* {userData && userData.map((user, index) => (
                <MobileView key={index} sno={user.id} name={user.company_name} subname={user.mobile_number} place={user.place} />
              ))} */}
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />{" "}
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={userData}
                  type="company"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Company;
