import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import API_DOMAIN from "../config/config";
import { TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import MobileView from "../components/MobileView";
import Spinner from "react-bootstrap/Spinner";
const UserTablehead = ["No", "Unit Type", "Action"];

// const userData = [
//   {
//     "id": "1",
//     "value": "Case",

//   },
//   {
//     "id": "2",
//     "value": "Piece",

//   },
//   {
//     "id": "3",
//     "value": "Unit",

//   },
// ]
const Unit = () => {
  //console.log(rowData);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this value according to your requirement
  const [formData, setFormData] = useState({
    RoleSelection: "",
    FireWorksName: "",
  });

  console.log(formData);
  const [userData, setUserData] = useState([]);
  console.log(userData);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/unit.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: searchText,
            action: "listUnit",
          }),
        });

        setLoading(false);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();

        if (responseData.head.code === 200) {
          let data = responseData.body.units || [];

          // Frontend filtering
          if (searchText) {
            data = data.filter((unit) =>
              unit.unit_type.toLowerCase().includes(searchText.toLowerCase())
            );
          }

          setUserData(data);
        } else {
          throw new Error(responseData.head?.msg || "Unknown error occurred");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchText, currentPage]);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="6" xs="6">
            <div className="page-nav py-3">
              <span class="nav-list"> Unit</span>
            </div>
          </Col>
          <Col lg="5" md="6" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/master/unit/create")}
            ></ClickButton>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-3 align-self-center">
            <TextInputForm
              placeholder={"Unit Type"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search"}
            >
              {" "}
            </TextInputForm>
          </Col>
          <Col lg={9} md={6} xs={12} className="py-3 align-self-center">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {/* {userData && userData.map((user, index) => (
                <MobileView key={index} sno={user.id} name={user.name} subname={user.role} />
              ))} */}
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />{" "}
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={userData}
                  type="unit"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>

          <Col lg={12} md={12} xs={12}>
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Unit;
