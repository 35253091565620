import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';

function generatePDF(formData, stockData) {
    // Define styles for the PDF
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4',
            padding: 10,
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        title: {
            fontSize: 24,
            textAlign: 'center',
            marginBottom: 10,
        },
        subtitle: {
            fontSize: 16,
            textAlign: 'center',
            marginBottom: 10,
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
        },
        tableRow: { margin: 'auto', flexDirection: 'row' },
        tableCell: {
            margin: 'auto',
            marginTop: 5,
            marginBottom: 5,
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
    });

    // Create a PDF document
    const MyDocument = (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.title}>Maha Vilvam Pyropark Report</Text>
                    <Text style={styles.subtitle}>
                        {formData.fireworkname ? `Firework Name: ${formData.fireworkname}` : ''}
                    </Text>
                    <Text style={styles.subtitle}>
                        {formData.productsName ? `Products Name: ${formData.productsName}` : ''}
                    </Text>
                    <Text style={styles.subtitle}>
                        {formData.fromDate ? `From Date: ${formData.fromDate}` : ''}
                    </Text>
                    <Text style={styles.subtitle}>
                        {formData.toDate ? `To Date: ${formData.toDate}` : ''}
                    </Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>S No</Text>
                            <Text style={styles.tableCell}>Product</Text>
                            <Text style={styles.tableCell}>Unit</Text>
                            <Text style={styles.tableCell}>Sub Unit</Text>
                        </View>
                        {stockData.map((item, index) => (
                            <View key={item.id} style={styles.tableRow}>
                                <Text style={styles.tableCell}>{index + 1}</Text>
                                <Text style={styles.tableCell}>{item.product_name}</Text>
                                <Text style={styles.tableCell}>
                                    {item.unit_type ? `${item.case_count} ${item.unit_type}` : '-'}
                                </Text>
                                <Text style={styles.tableCell}>
                                    {item.subUnitType ? `${item.case_count} ${item.subUnitType}` : '-'}
                                </Text>
                            </View>
                        ))}
                    </View>
                </View>
            </Page>
        </Document>
    );

    // Render the PDF document in a PDFViewer
    return (
        <PDFViewer width="100%" height="600px">
            {MyDocument}
        </PDFViewer>
    );
}

export default generatePDF;
