import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { DropDownUI, TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import MobileView from "../components/MobileView";
import API_DOMAIN from "../config/config";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DropDown } from "../components/Forms";
import { FaArrowCircleLeft } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
const UserTablehead = ["No", "Group", "Category", "Action"];

const Category = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this value according to your requirement

  const [userData, setUserData] = useState([]);

  console.log(userData);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLoad = () => {
    setFormData({
      Group_type: "",
      Category_type: "",
    });
  };
  const [formData, setFormData] = useState({
    Group_type: "",
    Category_type: "",
  });

  console.log(formData);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading indicator
        const response = await fetch(`${API_DOMAIN}/category.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "listCategory", // Determine action
            search_text: searchText, // Send search text if filtering
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json(); // Parse JSON response
        setLoading(false); // Stop loading indicator
        console.log("AA", responseData);
        // Check the response status
        if (responseData.status === 200) {
          let sortedData = responseData.data;

          // Apply additional filtering if needed
          if (formData.Group_type) {
            sortedData = sortedData.filter(
              (item) => item.Group_type === formData.Group_type
            );
          }

          if (formData.Category_type) {
            sortedData = sortedData.filter(
              (item) => item.Category_type === formData.Category_type
            );
          }
          if (searchText) {
            sortedData = sortedData.filter((Category) =>
              Category.Category_type.toLowerCase().includes(
                searchText.toLowerCase() || []
              )
            );
          }

          console.log("searchText", searchText);
          setUserData(sortedData); // Set filtered data in state
        } else {
          // Handle error or empty response
          console.error(
            "Error fetching data:",
            responseData.message || "Unknown error"
          );
          setUserData([]); // Set empty data on failure
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false); // Stop loading indicator
      }
    };

    fetchData();
  }, [searchText, currentPage, formData]);

  const handleSearch = (value) => {
    setSearchText(value.trim());
    setCurrentPage(1); // Reset to first page
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="6" xs="6">
            <div className="page-nav py-3">
              <span class="nav-list"> Category</span>
            </div>
          </Col>
          <Col lg="5" md="6" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/master/category/create")}
            ></ClickButton>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-1">
            <TextInputForm
              placeholder={"CategoryType"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search"}
            >
              {" "}
            </TextInputForm>
          </Col>
          <Col lg={6} md={6} xs={12} className="py-2 text-end">
            <span>
              <Button onClick={handleShow} className="filter my-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Filter
              </Button>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
              className="offscreen"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoMdCloseCircle className="iomdclose" />
                  </Button>
                  <div>
                    <Button onClick={handleLoad} className="filter mx-2">
                      <span className="me-2">
                        <IoFilter />
                      </span>
                      Undo Filter
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={userData.map((category) => ({
                          value: category.Group_type,
                          label: category.Group_type,
                        }))}
                        placeholder="Grouptype"
                        labelname="Grouptype"
                        name="Group_type"
                        value={formData.Group_type}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            Group_type: updatedFormData.Group_type,
                          })
                        }
                      />
                    </Col>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={userData.map((category) => ({
                          value: category.Category_type,
                          label: category.Category_type,
                        }))}
                        labelname={"CategoryType"}
                        placeholder="CategoryType"
                        name="Category_type"
                        value={formData.Category_type}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            Category_type: updatedFormData.Category_type,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  {/* <div className='mt-5'>
                                        <span className='mx-2'>
                                            <ClickButton label={<> Submit</>} onClick={handleSubmit} disabled={loading}></ClickButton>
                                        </span>
                                        <span className='mx-2'>
                                            <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                                        </span>
                                    </div> */}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg={3} md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {/* {userData && userData.map((user, index) => (
                                <MobileView key={index} sno={user.id} name={user.name} subname={user.role} />
                            ))} */}
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />{" "}
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={userData}
                  type="category"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>

          <Col lg={12} md={12} xs={12}>
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Category;
