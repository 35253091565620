import React, { useState, useEffect } from "react";
import { Container, Col, Row, OffcanvasHeader } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { DropDownUI, TextInputForm } from "../components/Forms";
import API_DOMAIN from "../config/config";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate, Navigate } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DropDown } from "../components/Forms";

import { IoFilter } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
const UserTablehead = ["No", "Name", " Role", " Mobile Number", "Action"];

const DropList = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Super Admin",
    label: "Super Admin",
  },
  {
    value: "Staff",
    label: "Staff",
  },
];

const User = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this value according to your requirement

  console.log(userData);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    RoleSelection: "",
    FireWorksName: "",
  });
  const handleLoad = () => {
    setFormData({
      RoleSelection: "",
      FireWorksName: "",
    });
  };
  console.log(formData);

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/users.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listUsers", // Add action parameter
          search_text: searchText,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("Fetched Data:", responseData); // Correct placement for debugging
      setLoading(false);

      if (
        responseData.head.code === 200 &&
        responseData.head.msg === "Success"
      ) {
        let sortedData = responseData.body.users;

        // Filter RoleSelection
        if (formData.RoleSelection) {
          sortedData = sortedData.filter(
            (user) => user.RoleSelection === formData.RoleSelection
          );
        }

        // Filter FireWorksName
        if (formData.FireWorksName) {
          sortedData = sortedData.filter(
            (user) => user.FireWorksName === formData.FireWorksName
          );
        }

        setUserData(sortedData);
      } else {
        throw new Error(responseData.head.msg || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem("session");
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking session:", error.message);
        setIsLoggedIn(false);
      }
    };
    fetchData();
    checkSession();
  }, [searchText, currentPage, formData]);

  const [factoryData, setFactoryData] = useState([]);
  useEffect(() => {
    const fetchfactoryData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/factory.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "listfactory", // Add action parameter
            search_text: "",
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        console.log("Factory Data:", responseData);

        setLoading(false);

        if (
          responseData.head.code === 200 &&
          responseData.head.msg === "Success"
        ) {
          setFactoryData(responseData.body.factories);
        } else {
          throw new Error(responseData.head.msg || "Unknown error");
        }
      } catch (error) {
        console.error("Error fetching factory data:", error.message);
        setLoading(false);
      }
    };

    fetchfactoryData();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);

    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="7" xs="6">
            <div className="page-nav py-3">
              <span class="nav-list"> Users</span>
            </div>
          </Col>
          <Col lg="5" md="5" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/user/create")}
            ></ClickButton>
          </Col>

          <Col lg="3" md="6" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Name"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search"}
            >
              {" "}
            </TextInputForm>
          </Col>
          <Col lg={6} md={6} xs={12} className="py-2 text-end md-mt-1">
            <span>
              <Button onClick={handleShow} className="filter my-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Filter
              </Button>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
              className="offscreen"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoMdCloseCircle className="iomdclose" />
                  </Button>
                  <div>
                    <Button onClick={handleLoad} className="filter mx-2">
                      <span className="me-2">
                        <IoFilter />
                      </span>
                      Undo Filter
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={DropList}
                        placeholder="Role Selection"
                        labelname="Role Selection"
                        name="RoleSelection"
                        value={formData.RoleSelection}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            RoleSelection: updatedFormData.RoleSelection,
                          })
                        }
                      />
                    </Col>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={factoryData.map((user) => ({
                          value: user.factory_name,
                          label: user.factory_name,
                        }))}
                        labelname={"Fireworks Name"}
                        placeholder="Fireworks Name"
                        name="FireWorksName"
                        value={formData.FireWorksName}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            FireWorksName: updatedFormData.FireWorksName,
                          })
                        }
                      />
                    </Col>
                  </Row>
                
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg={3} md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
            
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />{" "}
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={userData}
                  type="user"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>

          <Col lg={12} md={12} xs={12}>
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default User;
