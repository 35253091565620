import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import API_DOMAIN from "../config/config";
import { DropDownUI } from "../components/Forms";
import { IoFilter } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";

const UserTablehead = [
  "No",
  "Contractor Name",
  "Fireworks Name", // Updated to display fireworks name
  "Place",
  "Action",
];

const Contract = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [factoryData, setFactoryData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({ fireworks_name: "" });
  const [loading, setLoading] = useState(false);

  console.log("formData", formData);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLoad = () => {
    setFormData({
      fireworks_name: "",
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          action: "listContractors",
          search_text: searchText,
        }),
      });
      setLoading(false);
      console.log("a", response);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.status === 200 && responseData.message === "Success") {
        let sortedData = responseData.data;
        console.log("b", responseData);
        // Apply additional filtering based on fireworks_name

        setUserData(sortedData); // Set the filtered data to state
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  console.log("userData", userData);
  const fetchfactoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listfactory",
          search_text: "",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("Factory Data:", responseData); // Log the data to check structure

      if (
        responseData.head.code === 200 &&
        responseData.head.msg === "Success"
      ) {
        setFactoryData(responseData.body.factories); // Set the data
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchfactoryData();
  }, [searchText, currentPage, formData]);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const processedUserData = userData
    .map((contractor) => {
      const products = JSON.parse(contractor.products || "[]"); // Parse the stringified JSON
      return {
        ...contractor,
        fireworks_name: products.length > 0 ? products[0].fireworks_name : "", // Extract fireworks_name
      };
    })
    .filter((contractor) => {
      return formData.fireworks_name
        ? contractor.fireworks_name === formData.fireworks_name
        : true;
    });

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="6" xs="6">
            <div className="page-nav py-3">
              <span className="nav-list">Contract</span>
            </div>
          </Col>
          <Col lg="5" md="6" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/master/contract/create")}
            ></ClickButton>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Contractor Name"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search"}
            />
          </Col>
          <Col lg="6" md="6" xs="12" className="py-2 text-end">
            <span>
              <Button onClick={handleShow} className="filter my-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Filter
              </Button>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
              className="offscreen"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoMdCloseCircle className="iomdclose" />
                  </Button>
                  <div>
                    <Button onClick={handleLoad} className="filter mx-2">
                      <span className="me-2">
                        <IoFilter />
                      </span>
                      Undo Filter
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={factoryData.map((contract) => ({
                          value: contract.factory_name,
                          label: contract.factory_name,
                        }))}
                        placeholder="Fireworks Name"
                        labelname="Fireworks Name"
                        name="fireworks_name"
                        value={formData.fireworks_name}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            fireworks_name: updatedFormData.fireworks_name,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg="3" md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={processedUserData}
                  type="contract"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>
          <Col lg="12" md="12" xs="12">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contract;
