import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { TextInputForm } from "../components/Forms";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ClickButton } from "../components/ClickButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_DOMAIN from "../config/config";
const MagazineCreation = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const navigate = useNavigate();
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          edit_factory_id: "",
          magazine_name: "",
          factory_name: "",
          palce: "",
        };

  const [formData, setFormData] = useState(initialState);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/magazine.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      setLoading(true);
      const responseData = await response.json();

      console.log(responseData);

      if (responseData.status === 200) {
        toast.success("🦄 Successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/factory");
        }, 1000);
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };
  const handleUpdateSubmit = async () => {
    console.log("Inside handleUpdateSubmit");
    setLoading(true);

    try {
      const response = await fetch(`${API_DOMAIN}/magazine.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          edit_magazine_id: rowData.id, // Include the company ID in the request
          magazine_name: formData.magazine_name,
          place: formData.place,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update company");
      }

      const responseData = await response.json();

      if (responseData.status === 200) {
        console.log("Company Updated successfully");
        // You might want to navigate or perform other actions here
        navigate("/console/master/factory");
      } else {
        console.error(
          responseData.message || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating company:", error.message);
    }

    setLoading(false);
  };
  return (
    <div className="main-content">
      <Container>
        <Row className="regular justify-content-center">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`Magazine${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Magazine Name"}
                labelname={"Magazine Name"}
                value={formData.magazine_name}
                onChange={(e) => handleChange(e, "magazine_name")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Magazine Name"}
                labelname={"Magazine Name"}
                value={
                  type === "view"
                    ? rowData.magazine_name
                    : formData.magazine_name
                }
                onChange={(e) => handleChange(e, "magazine_name")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Place"}
                labelname={"Place"}
                value={formData.place}
                onChange={(e) => handleChange(e, "place")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Place"}
                labelname={"Place"}
                value={type === "view" ? rowData.place : formData.place}
                onChange={(e) => handleChange(e, "place")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/master/magazine")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/master/magazine")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Submit</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/master/magazine")}
                        ></ClickButton>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MagazineCreation;
