import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form } from "react-bootstrap";
import API_DOMAIN from "../config/config";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import MobileView from "../components/MobileView";

const LocationTablehead = ["No", "Presents", "Obsents", "Action"];
const LocationData = [
  { values: ["1", "Sankar", "Sundaravel Fireworks", "Manager"] },
  { values: ["2", "Ranjith", "Sundaravel Fireworks", "SuperAdmin"] },
  { values: ["3", "Kannan", "Sundaravel Fireworks", "Staff"] },
];

const Attendance = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [userData, setUserData] = useState([]);

  console.log(userData);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/staff.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData); // Logging the entire response data for debugging

      if (responseData.status === 200 && responseData.message === "Success") {
        setUserData(responseData.data);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="4" xs="6">
            <div className="page-nav py-3">
              <span class="nav-list"> Attendance</span>
            </div>
          </Col>
          <Col lg="5" md="3" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/attendance/create")}
            ></ClickButton>
          </Col>
          <Col lg="3" md="5" xs="12" className="py-1">
            <Form className="form page-nav">
              <TextInputForm
                placeholder={"Name , Mobile No."}
                prefix_icon={<FaMagnifyingGlass />}
                labelname={"Search"}
              >
                {" "}
              </TextInputForm>
            </Form>
          </Col>
          <Col lg={9} md={12} xs={12} className="py-2">
            <Pagnation></Pagnation>
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              <TableUI
                headers={LocationTablehead}
                body={LocationData}
                style={{ borderRadius: "5px" }}
              />
              <MobileView
                sno={"01"}
                FactoryName={"Anand Fireworks"}
                Place={"Vettrilaiyurani"}
              ></MobileView>
            </div>
          </Col>

          <Col lg={12} md={12} xs={12}>
            <Pagnation></Pagnation>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Attendance;
