import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { TextInputForm } from "../components/Forms";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DropDownUI } from "../components/Forms";
import { InstantCreate, ClickButton } from "../components/ClickButton";
import { BiPlus } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_DOMAIN from "../config/config";

// Correct component name by capitalizing the first letter
const CategoryCreation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { type, rowData } = location.state || {};

  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          Group_type: "",
          Category_type: "",
        };
  const initialStateModal =
    type === "edit"
      ? { ...rowData }
      : {
          Group_type: "",
        };
  const [formDataModal, setFormDataModal] = useState(initialStateModal);
  const [formData, setFormData] = useState(initialState);

  const [userData, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleInputChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleModalSubmit = () => {
    // Update userData with the new Group Type from the modal
    const newFactoryName = formDataModal.Group_type;
    setUserData((prevState) => [...prevState, { Group_type: newFactoryName }]);
    setFormData((prevState) => ({
      ...prevState,
      Group_type: newFactoryName,
    }));
    handleCloseModal();
  };

  const handleSubmit = async () => {
    // Validate form data fields
    for (const key in formData) {
      if (formData[key] === "") {
        toast.error(`${key} cannot be empty!`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return; // Exit early if any field is empty
      }
    }

    // Include the action parameter in the request body
    const requestData = {
      ...formData,
      action: "createCategory", // Make sure this matches the action your backend is expecting
    };

    try {
      const response = await fetch(`${API_DOMAIN}/category.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData), // Send the form data with action
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        toast.success("🦄 Category Data Successfully Added!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/category");
        }, 1000);
      } else {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_DOMAIN}/category.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateCategory", // Add this action to match backend expectations
          edit_Category_id: rowData.id, // Include the category ID in the request
          Group_type: formData.Group_type,
          Category_type: formData.Category_type,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update category");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        toast.success("Category updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/category");
        }, 2000);
      } else {
        toast.error(
          responseData.head.msg || "Unknown error occurred during update",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.error("Error updating category:", error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/group.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "listGroups",
          }),
        });
        console.log("b", response);
        if (!response.ok) throw new Error("Failed to fetch data");

        const responseData = await response.json();
        console.log("a", responseData);
        if (responseData.head.code === 200) {
          setUserData(responseData.body.groups);
        } else {
          throw new Error(responseData.message || "Unknown error");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="main-content">
      <Container>
        <Row className="regular justify-content-center">
          <Col lg="12" md="12" xs="12" className="py-3 d-inline">
            <PageNav
              pagetitle={`Category${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <DropDownUI
              optionlist={userData.map((group) => ({
                value: group.Group_type,
                label: group.Group_type,
              }))}
              labelname={"Group Type"}
              placeholder="Group Type"
              name="Group_type"
              value={formData.Group_type}
              onChange={(updatedFormData) =>
                setFormData({
                  ...formData,
                  Group_type: updatedFormData.Group_type,
                })
              }
            />
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder={"Category Type"}
              labelname={"Category Type"}
              value={formData.Category_type}
              onChange={(e) => handleInputChange(e, "Category_type")}
            ></TextInputForm>
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/master/category")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/master/category")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Submit</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/master/category")}
                        ></ClickButton>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal for adding new Group Type */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Group Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInputForm
            placeholder="Group Type"
            labelname="Group Type"
            value={formDataModal.Group_type}
            onChange={(e) => setFormDataModal({ Group_type: e.target.value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <ClickButton label="Add" onClick={handleModalSubmit} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoryCreation;
