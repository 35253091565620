import React, { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { BsStopCircleFill } from "react-icons/bs";
import { TextInputForm } from "../components/Forms";
import { ClickButton } from "../components/ClickButton";

const AttendanceCreation = () => {
  const [status, setStatus] = useState({});

  const staffList = [
    { name: "John Doe", wages: "$1000" },
    { name: "Jane Smith", wages: "$1200" },
    { name: "Alice Johnson", wages: "$1100" },
    { name: "Bob Brown", wages: "$1050" },
  ];

  const handlePresentClick = (name) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [name]: "present",
    }));
  };

  const handleAbsentClick = (name) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [name]: "absent",
    }));
  };

  const getStatusColor = (name) => {
    if (status[name] === "present") {
      return "green";
    } else if (status[name] === "absent") {
      return "red";
    } else {
      return "black"; // Default color if neither present nor absent is selected
    }
  };

  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="6" md="6" xs="12" className="py-3">
            <PageNav pagetitle={"Attendance Creation"}></PageNav>
          </Col>
          <Col lg="6" md="6" xs="12" className="py-3 text-end">
            <ClickButton label="Print" />
          </Col>
          <Col xs="12">
            <Table>
              <thead>
                <tr>
                  <th className="w-75">Staff Name</th>
                  <th>Present</th>
                  <th>Absent</th>
                  <th>Wages</th>
                </tr>
              </thead>
              <tbody>
                {staffList.map((staff, index) => (
                  <tr key={index}>
                    <td>{staff.name}</td>
                    <td>
                      <div
                        className="present"
                        onClick={() => handlePresentClick(staff.name)}
                        style={{
                          color:
                            getStatusColor(staff.name) === "green"
                              ? "green"
                              : "gray",
                        }}
                      >
                        <BsStopCircleFill size={32} />
                      </div>
                    </td>
                    <td>
                      <div
                        className="absent"
                        onClick={() => handleAbsentClick(staff.name)}
                        style={{
                          color:
                            getStatusColor(staff.name) === "red"
                              ? "red"
                              : "gray",
                        }}
                      >
                        <BsStopCircleFill size={32} />
                      </div>
                    </td>
                    <td>
                      <div>
                        <TextInputForm />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col xs="12" className="py-3 text-center">
            <ClickButton label="Submit" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttendanceCreation;
