import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { DropDownUI, TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import API_DOMAIN from "../config/config";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DropDown } from "../components/Forms";
import { IoFilter } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
const UserTablehead = ["No", "FactoryName", "Place", "Action"];
const Factory = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this value according to your requirement
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleLoad = () => {
    setFormData({
      factory_name: "",
      place: "",
    });
  };
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    factory_name: "",
    place: "",
  });

  const [userData, setUserData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  console.log(formData);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listfactory", // Ensure the action is being passed
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("responseData", responseData);
      if (responseData.head.code === 200) {
        let sortedData = responseData.body.factories;

        // Filter by factory_name if it's in formData
        if (formData.factory_name) {
          sortedData = sortedData.filter(
            (factory) => factory.factory_name === formData.factory_name
          );
        }

        // Filter by place if it's in formData
        if (formData.place) {
          sortedData = sortedData.filter(
            (factory) => factory.place === formData.place
          );
        }

        // Filter by searchText
        if (searchText) {
          sortedData = sortedData.filter((factory) =>
            factory.factory_name
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        }

        setUserData(sortedData);
      } else {
        console.error("No data found");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchText, currentPage, formData]);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="12" md="12" xs="12">
            <div className="page-nav py-3">
              <span class="nav-list"> Factory</span>
            </div>
          </Col>
          {/* <Col lg="5" md="6" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/master/factory/create")}
            ></ClickButton>
          </Col> */}
          <Col lg="3" md="6" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Name "}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search"}
            >
              {" "}
            </TextInputForm>
          </Col>
          <Col lg={6} md={6} xs={12} className="py-2 text-end md-mt-1">
            <span>
              <Button onClick={handleShow} className="filter my-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Filter
              </Button>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
              className="offscreen"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoMdCloseCircle className="iomdclose" />
                  </Button>
                  <div>
                    <Button onClick={handleLoad} className="filter mx-2">
                      <span className="me-2">
                        <IoFilter />
                      </span>
                      Undo Filter
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={userData.map((factory) => ({
                          value: factory.factory_name,
                          label: factory.factory_name,
                        }))}
                        placeholder="Factory Name"
                        labelname="Factory Name"
                        name="factory_name"
                        value={formData.factory_name}
                        onChange={(selectedValue) =>
                          setFormData({
                            ...formData,
                            factory_name: selectedValue.factory_name,
                          })
                        }
                      />
                    </Col>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={userData.map((factory) => ({
                          value: factory.place,
                          label: factory.place,
                        }))}
                        labelname={"Place"}
                        placeholder="Place"
                        name="place"
                        value={formData.place}
                        onChange={(selectedValue) =>
                          setFormData({
                            ...formData,
                            place: selectedValue.place,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  {/* <div className='mt-5'>
                    <span className='mx-2'>
                      <ClickButton label={<> Submit</>} onClick={handleSubmit} disabled={loading}></ClickButton>
                    </span>
                    <span className='mx-2'>
                      <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                    </span>
                  </div> */}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg={3} md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {/* {userData && userData.map((user, index) => (
                <MobileView key={index} sno={user.id} name={user.name} subname={user.role} />
              ))} */}
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />{" "}
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={userData}
                  type="factory"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>

          <Col lg={12} md={12} xs={12}>
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Factory;
