import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import PageNav from "../components/PageNav";
import API_DOMAIN from "../config/config";
import { TextInputForm, DropDownUI, Calender } from "../components/Forms";
import { ClickButton } from "../components/ClickButton";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PayrollCreation = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const today = new Date();
  const defaultDate = today.toISOString().substr(0, 10);
  const initialFormData =
    type === "edit"
      ? {
          ...rowData,
          products: JSON.parse(rowData.products || "[]"),
          fireWorksName: rowData.fireworks_name,
          contractorName: rowData.contractor_name,
          date: rowData.from_date,
        }
      : {
          date: defaultDate,
          fireWorksName: "",
          contractorName: "",
          products: [],
        };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [userData, setUserData] = useState([]);
  const [contractorData, setcontractorData] = useState([]);
console.log(formData);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [factoryData, setFactoryData] = useState([]);
  const [productData, setproductData] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [subUnitOptions, setSubUnitOptions] = useState([]);

  const handleDelete = (index) => {
    const updatedProducts = formData.products.filter(
      (product, i) => i !== index
    );
    setFormData({ ...formData, products: updatedProducts });
  };

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    if (fieldName === "caseCount" || fieldName === "coolyRate") {
      // Parse numeric values for calculation
      const caseCount =
        parseFloat(fieldName === "caseCount" ? value : formData.caseCount) || 0;
      const coolyRate =
        parseFloat(fieldName === "coolyRate" ? value : formData.coolyRate) || 0;

      // Recalculate the total
      const total = caseCount * coolyRate;

      // Update form data
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        total, // Update total whenever caseCount or coolyRate changes
      }));
    } else if (fieldName === "unitType") {
      // Parse the contractor's products
      const selectedContractor = contractorData.find(
        (contractor) => contractor.contractor_name === formData.contractorName
      );
      const products = JSON.parse(selectedContractor?.products || "[]");
      const selectedProductContractor = products.find(
        (product) => product.product_name === formData.productName
      );
      const selectedProduct = productData.find(
        (product) => product.product_name === formData.productName
      );

      // Calculate the quantity and total
      const quantity = selectedProduct?.Sub_count || 1;
      const coolyRate = selectedProductContractor?.cooly_rate || 0;
      const total =
        (selectedProductContractor?.SubUnit_type ? quantity : 1) * coolyRate;

      setFormData((prevData) => ({
        ...prevData,
        unitType: value,
        subUnitType: "", // Reset sub-unit when unit is selected
        caseCount: 1,
        coolyRate,
        total,
      }));
    } else if (fieldName === "subUnitType") {
      // Parse the contractor's products
      const selectedContractor = contractorData.find(
        (contractor) => contractor.contractor_name === formData.contractorName
      );
      const products = JSON.parse(selectedContractor?.products || "[]");
      const selectedProduct = productData.find(
        (product) => product.product_name === formData.productName
      );
      const selectedProductContractor = products.find(
        (product) => product.product_name === formData.productName
      );

      // Calculate the quantity and coolyRate
      const quantity = selectedProduct?.Sub_count || 1;
      const coolyRate =
        selectedProduct?.SubUnit_type ===
        selectedProductContractor?.SubUnit_type
          ? selectedProductContractor?.cooly_rate || 0
          : parseFloat(
              (selectedProductContractor?.cooly_rate / quantity).toFixed(2)
            );
      const total = 1 * coolyRate;

      setFormData((prevData) => ({
        ...prevData,
        unitType: "", // Reset unit when sub-unit is selected
        subUnitType: value,
        caseCount: 1,
        coolyRate,
        total,
      }));
    } else {
      // Handle other fields
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }

    // Handle date field specifically
    if (fieldName === "date") {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const setLabel = (date, label) => {
    const formatDate = (inputDate) => {
      const d = new Date(inputDate);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
      const day = String(d.getDate()).padStart(2, '0'); // Ensure 2 digits
      return `${year}-${month}-${day}`;
    };
  
    handleChange(date, label);
  
    if (label === "date") {
      setFormData((prevData) => ({
        ...prevData,
        date: formatDate(date), // Format the date
      }));
    }
  };
  

  const handleAddMore = () => {
    const { productName, Group_type, caseCount, coolyRate, total, date } = formData;
  
    // Check if any field is empty
    if (!productName || !Group_type || !caseCount || !coolyRate || !total) {
      toast.error("Please fill out all the fields."); // Display a toast message
      return;
    }
  
    setLoading(true);
  
    setFormData((prevState) => ({
      ...prevState,
      products: [
        ...prevState.products,
        {
          productName: prevState.productName,
          Group_type: prevState.Group_type,
          caseCount: prevState.caseCount,
          unitType: prevState.unitType,
          total: prevState.total,
          subUnitType: prevState.subUnitType,
          coolyRate: prevState.coolyRate,
        },
      ],
      Group_type: "",
      productName: "",
      caseCount: "",
      unitType: "",
      subUnitType: "",
      coolyRate: "",
      total: "",
    }));
  
    setLoading(false);
  };
  
  const handleSubmit = async () => {
    setLoading(true);
    if (formData.products.length === 0) {
      toast.error(`Products cannot be empty!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "colored",
      });
      return; // Exit if no products are present
    }
    try {
      const response = await fetch(`${API_DOMAIN}/payroll.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "createPayrolls",
          from_date: formData.date,
          fireworks_name: formData.fireWorksName,
          contractor_name: formData.contractorName,
          products: formData.products,
        }),
      });
      console.log(
        JSON.stringify({
          action: "createPayrolls",
          from_date: formData.date,
          fireworks_name: formData.fireWorksName,
          contractor_name: formData.contractorName,
          products: formData.products,
        })
      );
      if (!response.ok) {
        throw new Error("Failed to create payroll");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        toast.success("Successfull create payroll and Megazin stock!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/payroll");
        }, 2000);
      } else {
        console.error(
          responseData.head.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error creation payroll:", error.message);
    }

    setLoading(false);
  };
  const fetchfactoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listfactory",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        setFactoryData(responseData.body.factories);
        setFormData((prevData) => ({
          ...prevData,

          fireWorksName: responseData.body.factories[0].factory_name,
        }));
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchDatacon = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listContractors",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.status === 200 && responseData.message === "Success") {
        let sortedData = responseData.data;
        console.log("sor", sortedData);

        setcontractorData(sortedData);
        console.log("contract", sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchproductData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/product.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            action: "listProduct",
          }),
        });
        setLoading(false);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        // Logging the entire response data for debugging

        if (responseData.head.code === 200) {
          let sortedData = responseData.body.products;

          setproductData(sortedData);
        } else {
          throw new Error(
            responseData.message ? responseData.message : "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchproductData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/group.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            action: "listGroups",
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        // Logging the entire response data for debugging
        setLoading(false);
        if (responseData.head.code === 200) {
          setUserData(responseData.body.groups);
        } else {
          throw new Error(
            responseData.message ? responseData.message : "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchData();
    fetchfactoryData();
    fetchDatacon();
  }, []);
  const handleUpdateSubmit = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${API_DOMAIN}/payroll.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updatePayroll",
          edit_Payroll_id: rowData.id,
          fireworks_name: formData.fireWorksName,
          contractor_name: formData.contractorName,
          products: formData.products,
        }),
      });
      console.log(JSON.stringify({
        action: "updatePayroll",
        edit_Payroll_id: rowData.id,
        fireworks_name: formData.fireWorksName,
        contractor_name: formData.contractorName,
        products: formData.products,
      }));

      if (!response.ok) {
        throw new Error("Failed to update company");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        toast.success("Successfully update Payroll!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // Navigate to the user list page after a delay
        setTimeout(() => {
          navigate("/console/payroll");
        }, 2000);
      } else {
        console.error(
          responseData.head.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating :", error.message);
    }

    setLoading(false);
  };
  const optioncontractorlist = contractorData
    .filter((factory) => {
      const products = JSON.parse(factory.products || "[]");
      return products.some(
        (product) => product.fireworks_name === formData.fireWorksName
      );
    })
    .map((factory) => ({
      value: factory.contractor_name,
      label: factory.contractor_name,
    }));

  const [GroupData, setGroupData] = useState([]);

  const [UnitData, setUnitData] = useState([]);
  const fetchGroupData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/group.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listGroups",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData); // Logging the entire response data for debugging
      setLoading(false);
      if (responseData.head.code === 200) {
        setGroupData(responseData.body.groups);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchGroupData();
    // fetchCategoryData();
    fetchUnitData();
  }, []);
  // const fetchCategoryData = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await fetch(`${API_DOMAIN}/category.php`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         search_text: "",
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch data");
  //     }

  //     const responseData = await response.json();
  //     console.log(responseData); // Logging the entire response data for debugging
  //     setLoading(false);
  //     if (responseData.status === 200 && responseData.message === "Success") {
  //       setCategoryData(responseData.data);
  //     } else {
  //       throw new Error(
  //         responseData.message ? responseData.message : "Unknown error"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //     setLoading(false);
  //   }
  // };
  console.log("formdata", formData);
  const fetchUnitData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/unit.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listUnit",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData); // Logging the entire response data for debugging
      setLoading(false);
      if (responseData.head.code === 200) {
        setUnitData(responseData.body.units);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const [showAlert, setShowAlert] = useState(false);

  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`PayRoll${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>
          <Col lg="2" md="4" xs="12" className="py-3">
            <Calender
              setLabel={(date) => setLabel(date, "date")}
              calenderlabel="Date"
              disabled={type === "view" || type === "edit"}
            />
          </Col>
          {/* ... (Other form fields) */}
          {/* <Col lg="3" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={factoryData.map((factory) => ({
                      value: factory.factory_name,
                      label: factory.factory_name,
                    }))}
                    placeholder="FireWorks Name"
                    labelname={"FireWorks Name"}
                    name="fireWorksName"
                    value={formData.fireWorksName}
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        fireWorksName: updatedFormData.fireWorksName,
                      })
                    }
                    isEdit={type === "edit"}
                  ></DropDownUI>
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={factoryData.map((factory) => ({
                      value: factory.factory_name,
                      label: factory.factory_name,
                    }))}
                    placeholder="FireWorks Name"
                    labelname={"FireWorks Name"}
                    name="fireWorksName"
                    value={
                      type === "view"
                        ? rowData.fireWorksName
                        : formData.fireWorksName
                    }
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        fireWorksName: updatedFormData.fireWorksName,
                      })
                    }
                    isEdit={type === "edit"}
                  ></DropDownUI>
                </div>
              </div>
            )}
          </Col> */}
          <Col lg="3" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={optioncontractorlist}
                    placeholder="Contractor Name"
                    labelname={"contractor Name"}
                    name="contractorName"
                    value={formData.contractorName}
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        contractorName: updatedFormData.contractorName,
                      })
                    }
                  ></DropDownUI>
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={optioncontractorlist}
                    placeholder="Contractor Name"
                    labelname={"contractor Name"}
                    name="contractorName"
                    value={
                      type === "view"
                        ? rowData.contractorName
                        : formData.contractorName
                    }
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        contractorName: updatedFormData.contractorName,
                      })
                    }
                  ></DropDownUI>
                </div>
              </div>
            )}
          </Col>
          {/* ... (Other form fields) */}
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <DropDownUI
                optionlist={userData.map((factory) => ({
                  value: factory.Group_type,
                  label: factory.Group_type,
                }))}
                labelname={"Group Type"}
                placeholder="Group Type"
                name="Group_type"
                value={formData.Group_type}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    Group_type: updatedFormData.Group_type,
                  })
                }
              />
            ) : (
              <DropDownUI
                optionlist={userData.map((factory) => ({
                  value: factory.Group_type,
                  label: factory.Group_type,
                }))}
                labelname={"Group Type"}
                placeholder="Group Type"
                name="Group_type"
                value={
                  type === "view" ? rowData.Group_type : formData.Group_type
                }
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    Group_type: updatedFormData.Group_type,
                  })
                }
              />
            )}
          </Col>

          <Col lg="3" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={productData.map((user) => ({
                      value: user.product_name,
                      label: user.product_name,
                    }))}
                    labelname={"Product Name"}
                    placeholder="Product Name"
                    name="productName"
                    value={formData.productName}
                    onChange={(updatedFormData) => {
                      setFormData({
                        ...formData,
                        productName: updatedFormData.productName,
                      });

                      const selectedProduct = productData.find(
                        (user) =>
                          user.product_name === updatedFormData.productName
                      );
                      console.log("selkecccc", selectedProduct);
                      setUnitOptions(
                        selectedProduct?.Unit_type
                          ? [selectedProduct.Unit_type]
                          : []
                      );
                      setSubUnitOptions(
                        selectedProduct?.SubUnit_type
                          ? [selectedProduct.SubUnit_type]
                          : []
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={productData.map((user) => ({
                      value: user.product_name,
                      label: user.product_name,
                    }))}
                    labelname={"Product Name"}
                    placeholder="Product Name"
                    name="productName"
                    value={
                      type === "view"
                        ? rowData.productName
                        : formData.productName
                    }
                    onChange={(updatedFormData) => {
                      setFormData({
                        ...formData,
                        productName: updatedFormData.productName,
                      });

                      const selectedProduct = productData.find(
                        (user) =>
                          user.product_name === updatedFormData.productName
                      );
                      console.log("selkecccc", selectedProduct);
                      setUnitOptions(
                        selectedProduct?.Unit_type
                          ? [selectedProduct.Unit_type]
                          : []
                      );
                      setSubUnitOptions(
                        selectedProduct?.SubUnit_type
                          ? [selectedProduct.SubUnit_type]
                          : []
                      );
                    }}
                  />
                </div>
              </div>
            )}
            <Row>
              <Col lg="6" md="6" xs="6" className="py-1">
                <div className="text-center">
                  {unitOptions.map((option, index) => (
                    <React.Fragment key={index}>
                      <input
                        type="radio"
                        id={`unit_${index}`}
                        name="unitType"
                        value={option}
                        checked={formData.unitType === option}
                        onChange={(e) => handleChange(e, "unitType")}
                      />
                      <label htmlFor={`unit_${index}`}>{option}</label>
                    </React.Fragment>
                  ))}
                </div>
              </Col>
              <Col lg="6" md="6" xs="6" className="py-1">
                <div>
                  {subUnitOptions.map((option, index) => (
                    <React.Fragment key={index}>
                      <input
                        type="radio"
                        id={`subUnit_${index}`}
                        name="subUnitType"
                        value={option}
                        checked={formData.subUnitType === option}
                        onChange={(e) => handleChange(e, "subUnitType")}
                      />
                      <label htmlFor={`subUnit_${index}`}>{option}</label>
                    </React.Fragment>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg="3" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder={" Count"}
              name="caseCount"
              labelname={" Count"}
              value={formData.caseCount}
              onChange={(e) => handleChange(e, "caseCount")}
            ></TextInputForm>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder={"Cooly Rate"}
              labelname={"Cooly Rate"}
              name="coolyRate"
              value={formData.coolyRate}
              onChange={(e) => handleChange(e, "coolyRate")}
            ></TextInputForm>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder={"Total"}
              labelname={"Total"}
              name="total"
              value={formData.total}
              onChange={(e) => handleChange(e, "total")}
            ></TextInputForm>
          </Col>
          {/* ... (Other form fields) */}
          <Col lg="3" md="4" xs="12" className="py-3 align-self-center">
            <div className="text-center">
              <ClickButton
                label={<> Add More</>}
                onClick={handleAddMore}
                disabled={loading}
              ></ClickButton>
            </div>
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/payroll")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/payroll")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Submit</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/payroll")}
                        ></ClickButton>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {formData.products.length > 0 && (
        <Container>
          <Table striped bordered hover>
            <thead>
              <tr>
               
                {/* <th>FireWorks Name</th> */}
                <th>Contractor Name</th>
                <th>Group Type</th>
                <th>Product Name</th>
                <th>Count</th>
                <th>Unit Type</th>
                <th>Sub Unit Type</th>
                <th>Cooly Rate</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {formData.products.map((entry, index) => (
                <tr key={index}>
                 
                  {/* <td>{formData.fireWorksName}</td> */}
                  <td>{formData.contractorName}</td>
                  <td>{entry.Group_type}</td>
                  <td>{entry.productName}</td>
                  <td>{entry.caseCount}</td>
                  <td>{entry.unitType}</td>
                  <td>{entry.subUnitType}</td>
                  <td>{entry.coolyRate}</td>
                  <td>{entry.total}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      )}
    </div>
  );
};

export default PayrollCreation;
