import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table, Modal, Button } from "react-bootstrap";
import API_DOMAIN from "../config/config";
import PageNav from "../components/PageNav";
import { TextInputForm, DropDownUI, Calender } from "../components/Forms";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ClickButton } from "../components/ClickButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeliverySlipCreation = () => {
  const today = new Date();
  const defaultDate = today.toISOString().substr(0, 10);
  const location = useLocation();
  const { type, rowData } = location.state || {};

  const initialFormData =
    type === "edit"
      ? {
          ...rowData,
          products: JSON.parse(rowData.products || "[]"),
          date: rowData.from_date,
        }
      : {
          date: defaultDate,
          fireworks_name: "",
          customer_name: "",
          address: "",
          mobile_number: "",
          gst: "",
          place: "",
          products: [],
        };
  const [formData, setFormData] = useState(initialFormData);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [factoryData, setFactoryData] = useState([]);
  const [productData, setproductData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [showRadioButtons, setShowRadioButtons] = useState(true);
  const [unitOptions, setUnitOptions] = useState([]);
  const [subUnitOptions, setSubUnitOptions] = useState([]);
  const handleDelete = (index) => {
    const updatedProducts = formData.products.filter(
      (product, i) => i !== index
    );
    setFormData({ ...formData, products: updatedProducts });
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    if (fieldName === "product_name") {
      const selectedProduct = productData.find(
        (product) => product.product_name === value
      );

      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        Unit_type: selectedProduct?.Unit_type || "",
        SubUnit_type: selectedProduct?.SubUnit_type || "",
      }));
    } else if (fieldName === "Unit_type") {
      // Handle "Unit" radio button click
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        SubUnit_type: "",
      }));
    } else if (fieldName === "SubUnit_type") {
      // Handle "Sub unit" radio button click
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        Unit_type: "",
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
    }
  };

  const fetchfactoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listfactory",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        setFactoryData(responseData.body.factories);
        setFormData((prevData) => ({
          ...prevData,

          fireworks_name: responseData.body.factories[0].factory_name,
        }));
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchproductData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/product.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            action: "listProduct",
          }),
        });
        setLoading(false);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        // Logging the entire response data for debugging

        if (responseData.head.code === 200) {
          let sortedData = responseData.body.products;

          setproductData(sortedData);
        } else {
          throw new Error(
            responseData.message ? responseData.message : "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchproductData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/customer.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            action: "listCustomers",
          }),
        });

        setLoading(false);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();

        if (responseData.head.code === 200) {
          let sortedData = responseData.body.customers;

          setUserData(sortedData);
        } else {
          throw new Error(responseData.message || "Unknown error occurred");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchData();
    fetchfactoryData();
  }, []);

  const handleSubmit = async () => {
    if (formData.products.length === 0) {
      toast.error(`Products cannot be empty!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "colored",
      });
      return; // Exit if no products are present
    }
    setLoading(true);

    try {
      const response = await fetch(`${API_DOMAIN}/delivery.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "createDelivery",
          from_date: formData.date,
          fireworks_name: formData.fireworks_name,
          customer_name: formData.customer_name,
          address: formData.address,
          place: formData.address,
          gst: formData.gst,
          mobile_number: formData.mobile_number,
          products: formData.products,
        }),
      });
      console.log(
        JSON.stringify({
          action: "createDelivery",
          from_date: formData.date,
          fireworks_name: formData.fireworks_name,
          customer_name: formData.customer_name,
          address: formData.address,
          place: formData.address,
          gst: formData.gst,
          mobile_number: formData.mobile_number,
          products: formData.products,
        })
      );
      console.log("deliveryresponse", response);
      const responseData = await response.json();
      console.log("deliveryresponsedata", responseData);
      if (responseData.head.code === 200) {
        toast.success(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          navigate("/console/delivery", {
            state: { type: "success" },
          });
        }, 2000);
      } else {
        toast.error(responseData.head.msg || "Failed to create payroll", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error creating payroll:", error.message);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const setDateOfBirth = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      date: date,
    }));
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: value, // Update the specified field in formData with the provided value
    });
  };
  const handleUpdateSubmit = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${API_DOMAIN}/delivery.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateDelivery",
          delivery_id: rowData.id, // Include the company ID in the request
          fireworks_name: formData.fireworks_name,
          customer_name: formData.customer_name,
          address: formData.address,
          place: formData.address,
          gst: formData.gst,
          mobile_number: formData.mobile_number,
          products: formData.products,
        }),
      });
      console.log(
        JSON.stringify({
          action: "updateDelivery",
          delivery_id: rowData.id, // Include the company ID in the request
          fireworks_name: formData.fireworks_name,
          customer_name: formData.customer_name,
          address: formData.address,
          place: formData.address,
          gst: formData.gst,
          mobile_number: formData.mobile_number,
          products: formData.products,
        })
      );
      const responseData = await response.json();

      if (responseData.head.code === 200) {
        toast.success(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/delivery");
        }, 2000);
      } else {
        console.error(
          responseData.head.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.message);
    }

    setLoading(false);
  };
  const handleAddMore = () => {
    setLoading(true);
    const { productName, quantity } = formData;

    // Validation: Check if any field is empty
    if (!productName || !quantity) {
      toast.error("Please fill out all the fields."); // Show a toast message
      return; // Exit the function if validation fails
    }

    setFormData((prevState) => ({
      ...prevState,
      products: [
        ...prevState.products,
        {
          productName: prevState.productName,
          quantity: prevState.quantity,
          Unit_type: prevState.Unit_type,
          SubUnit_type: prevState.SubUnit_type,
        },
      ],
      productName: "",
      quantity: "",
      Unit_type: "",
      SubUnit_type: "",
    }));

    setLoading(false);
  };
  console.log("formdata", formData);

  const [UnitData, setUnitData] = useState([]);

  useEffect(() => {
    fetchUnitData();
  }, []);

  const fetchUnitData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/unit.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listUnit",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      setLoading(false);
      if (responseData.head.code === 200) {
        setUnitData(responseData.body.units);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  return (
    <div className="main-content">
      <Container>
        <Row className="justify-content-center">
          <Col lg="12" md="12" xs="12" className="py-3 text-center">
            <PageNav
              pagetitle={`DeliverySlip ${
                type === "view"
                  ? "View"
                  : type === "edit"
                  ? "Update"
                  : "Creation"
              }`}
            />
          </Col>

          {/* Calendar Section */}
          <Col lg="4" md="6" xs="12" className="py-3">
            <Calender
              setLabel={(date) => setLabel(date, "fromdate")}
              selectedDate={formData.data}
              onSelectDate={setDateOfBirth}
              calenderlabel="From Date"
            />
          </Col>

          {/* Fireworks Dropdown */}
          {/* <Col lg="4" md="6" xs="12" className="py-3">
            <DropDownUI
              optionlist={factoryData.map((item) => ({
                label: item.factory_name,
                value: item.factory_name,
              }))}
              placeholder="FireWorks Name"
              labelname="FireWorks Name"
              name="fireworks_name"
              value={
                type === "view"
                  ? rowData.fireworks_name
                  : formData.fireworks_name
              }
              onChange={(updatedFormData) =>
                setFormData({
                  ...formData,
                  fireworks_name: updatedFormData.fireworks_name,
                })
              }
            />
          </Col> */}

          {/* Form Inputs */}
          {[
            { label: "Customer Name", field: "customer_name" },
            { label: "Address", field: "address" },
            { label: "Place", field: "place" },
            { label: "Mobile No.", field: "mobile_number" },
            { label: "GST", field: "gst" },
          ].map(({ label, field }, index) => (
            <Col lg="3" md="4" xs="12" className="py-3" key={index}>
              <TextInputForm
                placeholder={label}
                labelname={label}
                value={type === "view" ? rowData[field] : formData[field]}
                onChange={(e) => handleChange(e, field)}
              />
            </Col>
          ))}

          {/* Product Dropdown with Radio Buttons */}
          <Col lg="4" md="6" xs="12" className="py-3">
            <DropDownUI
              optionlist={productData.map((user) => ({
                value: user.product_name,
                label: user.product_name,
              }))}
              placeholder="Product Name"
              labelname="Product Name"
              name="productName"
              value={
                type === "view" ? rowData.productName : formData.productName
              }
              onChange={(updatedFormData) => {
                setFormData({
                  ...formData,
                  productName: updatedFormData.productName,
                });
                const selectedProduct = productData.find(
                  (user) => user.product_name === updatedFormData.productName
                );
                const showRadioButtons = !!selectedProduct;
                setShowRadioButtons(showRadioButtons);
                setUnitOptions(
                  selectedProduct?.Unit_type ? [selectedProduct.Unit_type] : []
                );
                setSubUnitOptions(
                  selectedProduct?.SubUnit_type
                    ? [selectedProduct.SubUnit_type]
                    : []
                );
              }}
            />
            {showRadioButtons && (
              <Row>
                <Col lg="6" className="py-1 text-center">
                  {unitOptions.map((option, index) => (
                    <div key={index}>
                      <input
                        type="radio"
                        id={`unit_${index}`}
                        name="Unit_type"
                        value={option}
                        checked={formData.Unit_type === option}
                        onChange={(e) => handleChange(e, "Unit_type")}
                      />
                      <label htmlFor={`unit_${index}`}>{option}</label>
                    </div>
                  ))}
                </Col>
                <Col lg="6" className="py-1 text-center">
                  {subUnitOptions.map((option, index) => (
                    <div key={index}>
                      <input
                        type="radio"
                        id={`subUnit_${index}`}
                        name="SubUnit_type"
                        value={option}
                        checked={formData.SubUnit_type === option}
                        onChange={(e) => handleChange(e, "SubUnit_type")}
                      />
                      <label htmlFor={`subUnit_${index}`}>{option}</label>
                    </div>
                  ))}
                </Col>
              </Row>
            )}
          </Col>

          {/* Quantity Input */}
          <Col lg="3" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder="Quantity"
              labelname="Quantity"
              value={type === "view" ? rowData.quantity : formData.quantity}
              onChange={(e) => handleChange(e, "quantity")}
            />
          </Col>

          {/* Add More Button */}
          <Col lg="3" md="4" xs="12" className="py-3 text-center">
            <ClickButton
              label={<>Add More</>}
              onClick={handleAddMore}
              disabled={loading}
            />
          </Col>

          {/* Action Buttons */}
          <Col lg="12" className="py-5 text-center">
            {type === "view" ? (
              <ClickButton
                label={<>Back</>}
                onClick={() => navigate("/console/delivery")}
              />
            ) : (
              <>
                <ToastContainer
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar={false}
                  pauseOnHover
                  draggable
                  theme="colored"
                />
                <span className="mx-2">
                  <ClickButton
                    label={type === "edit" ? <>Update</> : <>Submit</>}
                    onClick={
                      type === "edit" ? handleUpdateSubmit : handleSubmit
                    }
                  />
                </span>
                <span className="mx-2">
                  <ClickButton
                    label={<>Cancel</>}
                    onClick={() => navigate("/console/delivery")}
                  />
                </span>
              </>
            )}
          </Col>
        </Row>
        {formData.products.length > 0 && (
          <Row>
            <Col lg="12" md="12" xs="12" className="py-3">
              <Table striped bordered hover className="table-fixed">
                <thead>
                  <tr>
                    <th>Date</th>
                    {/* <th>Firework Name</th> */}
                    <th>Customer Name</th>
                    <th>Address</th>
                    <th>Mobile Number</th>
                    <th>Place</th>
                    <th>GST</th>
                    <th>Product Name</th>
                    <th>Unit</th>
                    <th>Subunit</th>
                    <th>Quantity</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.products.map((entry, index) => (
                    <tr key={index}>
                      <td>{formData.date}</td>
                      {/* <td>{formData.fireworks_name}</td> */}
                      <td>{formData.customer_name}</td>
                      <td className="no-wrap">{formData.address}</td>
                      <td>{formData.mobile_number}</td>
                      <td className="no-wrap">{formData.place}</td>
                      <td>{formData.gst}</td>
                      <td>{entry.productName}</td>
                      <td>{entry.Unit_type}</td>
                      <td>{entry.SubUnit_type}</td>
                      <td>{entry.quantity}</td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDelete(index)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default DeliverySlipCreation;
