import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Tabs,
  Tab,
  Button,
  Offcanvas,
} from "react-bootstrap";
import API_DOMAIN from "../config/config";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { DropDownUI, TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import MobileView from "../components/MobileView";
import { IoFilter } from "react-icons/io5";
import { IoCloseCircle } from "react-icons/io5";
import { SlPrinter } from "react-icons/sl";
const LocationTablehead = ["No", "Party Name", "Mobile No", "Action"];
// const thHead = ["No", "Party Names", "Mobile No", "Action"];
const tbHead = ["No", "Party Name-s", "Mobile No", "Action"];
const LocationData = [
  { values: ["1", "Sankar", "Sundaravel Fireworks", "Manager"] },
  { values: ["2", "Ranjith", "Sundaravel Fireworks", "SuperAdmin"] },
  { values: ["3", "Kannan", "Sundaravel Fireworks", "Staff"] },
];

const OrderForm = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false); // Offcanvas visibility
  const [formData, setFormData] = useState({ fireworks_name: "" }); // Filter form data
  const [factoryData, setFactoryData] = useState([]); // Factory data for dropdown

  // Offcanvas handlers
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleLoad = () => {
    setFormData({ fireworks_name: "" }); // Reset filter data
    fetchData(); // Reload all data
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/staff.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.status === 200 && responseData.message === "Success") {
        setUserData(responseData.data);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="4" xs="6">
            <div className="page-nav py-3">
              <span className="nav-list">OrderForm</span>
            </div>
          </Col>
          <Col lg="5" md="3" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/orderform/create")}
            ></ClickButton>
          </Col>
          <Col lg="3" md="5" xs="12" className="py-1">
            <Form className="form page-nav">
              <TextInputForm
                placeholder={"Name , Mobile No."}
                prefix_icon={<FaMagnifyingGlass />}
                labelname={"Search"}
              />
            </Form>
          </Col>
          <Col lg={9} md={9} xs={12} className="py-2 text-end">
            <span className="mx-1">
              <Button onClick={handleShow} className="filter my-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Filter
              </Button>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>

            <span className="mx-1">
              <ClickButton
                label={
                  <>
                    <SlPrinter size={28} />
                  </>
                }
              ></ClickButton>
            </span>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
              className="offscreen"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoCloseCircle size={40} />
                  </Button>
                  <div>
                    <Button onClick={handleLoad} className="filter mx-2">
                      <span className="me-2">
                        <IoFilter />
                      </span>
                      Undo Filter
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={factoryData.map((contract) => ({
                          value: contract.factory_name,
                          label: contract.factory_name,
                        }))}
                        placeholder="Fireworks Name"
                        labelname="Fireworks Name"
                        name="fireworks_name"
                        value={formData.fireworks_name}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            fireworks_name: updatedFormData.fireworks_name,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg={12} md={12} xs={12} className="py-2">
            <Pagnation />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0 py-3">
            <Tabs defaultActiveKey="new" id="attendance-tabs">
              <Tab eventKey="new" title="New">
                <div className="py-3">
                  <TableUI
                    headers={LocationTablehead}
                    body={LocationData}
                    style={{ borderRadius: "5px" }}
                  />
                  <MobileView
                    sno={"01"}
                    FactoryName={"Anand Fireworks"}
                    Place={"Vettrilaiyurani"}
                  />
                </div>
              </Tab>
              <Tab eventKey="pending" title="Pending">
                <div className="py-3">
                  {/* Render pending data here */}
                  <TableUI
                    headers={tbHead}
                    body={LocationData}
                    style={{ borderRadius: "5px" }}
                  />
                  <MobileView
                    sno={"02"}
                    FactoryName={"Pending Fireworks"}
                    Place={"Pending Place"}
                  />
                </div>
              </Tab>
              {/* <Tab eventKey="completed" title="Completed">
                <div className="py-3">
                  
                  <TableUI
                    headers={thHead}
                    body={LocationData}
                    style={{ borderRadius: "5px" }}
                  />
                  <MobileView
                    sno={"03"}
                    FactoryName={"Completed Fireworks"}
                    Place={"Completed Place"}
                  />
                </div>
              </Tab> */}
            </Tabs>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <Pagnation />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderForm;
