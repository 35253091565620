import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import API_DOMAIN from "../config/config";
import { HiOutlineUsers } from "react-icons/hi2";
import { SlMagicWand } from "react-icons/sl";
import { CiDeliveryTruck } from "react-icons/ci";
const DashBoard = () => {
  const [userData, setUserData] = useState([]);
  const [productsData, setproductsData] = useState([]);
  const [deliveryData, setdeliveryData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listContractors",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.status === 200 && responseData.message === "Success") {
        setUserData(responseData.data);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const fetchdeliveryData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/delivery.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listDeliveries",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        setdeliveryData(responseData.body.deliveries);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const fetchfactoryData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/product.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listProduct",
        }),
      });
      console.log("a", response);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("b", responseData);
      // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        setproductsData(responseData.body.products);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem("session");
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking session:", error.message);
        setIsLoggedIn(false);
      }
    };
    fetchfactoryData();
    fetchData();
    fetchdeliveryData();
    checkSession();
  }, []);
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <div className="main-content my-4">
        <Container>
          <Row>
            <Col lg="4" md="6" xs="12" className="py-3">
              <div className="counter-card cyan">
                <span>
                  <HiOutlineUsers />
                </span>
                <span class="count-numbers plus bold">{userData.length}</span>
                <span class="count-name regular">Contractor</span>
              </div>
            </Col>
            <Col lg="4" md="6" xs="12" className="py-3">
              <div className="counter-card blue">
                <span>
                  <SlMagicWand />
                </span>
                <span class="count-numbers plus bold">
                  {productsData.length}
                </span>
                <span class="count-name regular">Products</span>
              </div>
            </Col>
            <Col lg="4" md="6" xs="12" className="py-3">
              <div className="counter-card red">
                <span>
                  <CiDeliveryTruck  />
                </span>
                <span class="count-numbers plus bold">
                  {deliveryData.length}
                </span>
                <span class="count-name regular">Delivery</span>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>{/* <DeliveryReportPDF></DeliveryReportPDF> */}</Container>
      </div>
    </>
  );
};

export default DashBoard;
