import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Calender, DropDownUI } from "../components/Forms";
import { ClickButton } from "../components/ClickButton";
import { IoFilter } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import API_DOMAIN from "../config/config";
import html2canvas from "html2canvas";
import generatePDF from "./reactpdf";
const logoImage = require("../components/sidebar/images/logo.jpeg");

const MagazineStock = () => {
  const initialFormData = {
    fireworkname: "",
    productsName: "",
  };
  const [loading, setLoading] = useState(false);
  const [stockData, setstockData] = useState([]);
  const [showExcelButton, setShowExcelButton] = useState(true);

  const [factoryData, setFactoryData] = useState([]);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [productData, setproductData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  console.log(productData);
  console.log(formData);
  const setDateOfBirth = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      date: date,
    }));
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: value, // Update the specified field in formData with the provided value
    });
  };
  const fetchproductbasedtData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/product.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listProduct",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.products;

        setproductData(sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchfactoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listfactory",
        }),
      });

      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        setFactoryData(responseData.body.factories);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchmagazinestockData = async () => {
    try {
      setLoading(true);

      // API request
      const response = await fetch(`${API_DOMAIN}/magazinestock.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listMagazineStock",
        }),
      });

      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.magazineStocks;

        // Debugging log for fetched data
        console.log(sortedData, "Fetched Deliveries");

        // Apply filters based on formData
        sortedData = sortedData.filter((delivery) => {
          // Filter by fireworks name
          if (
            formData.fireworkname &&
            delivery.fireworks_name !== formData.fireworkname
          ) {
            return false;
          }

          // Filter by date range
          const createDate = new Date(delivery.create_at);
          const fromDate = formData.fromdate
            ? new Date(formData.fromdate)
            : null;
          const toDate = formData.todate ? new Date(formData.todate) : null;

          if (
            fromDate &&
            toDate &&
            (createDate < fromDate || createDate > toDate)
          ) {
            return false;
          }
          if (fromDate && createDate < fromDate) {
            return false;
          }
          if (toDate && createDate > toDate) {
            return false;
          }

          // Filter by product name
          if (formData.productsName) {
            const products = JSON.parse(delivery.products || "[]");
            const productMatch = products.some(
              (product) => product.productName === formData.productsName
            );
            if (!productMatch) {
              return false;
            }
          }

          return true;
        });

        // Update state with filtered data
        setstockData(sortedData);
      } else {
        throw new Error(responseData.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  // const fetchproductData = async () => {
  //   try {
  //     setLoading(true);

  //     // API request
  //     const response = await fetch(`${API_DOMAIN}/delivery.php`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         search_text: "",
  //         action: "listDeliveries",
  //       }),
  //     });

  //     setLoading(false);
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch data");
  //     }

  //     const responseData = await response.json();

  //     if (responseData.head.code === 200) {
  //       let sortedData = responseData.body.deliveries;

  //       // Debugging log for fetched data
  //       console.log(sortedData, "Fetched Deliveries");

  //       // Apply filters based on formData
  //       sortedData = sortedData.filter((delivery) => {
  //         // Filter by fireworks name
  //         if (
  //           formData.fireworkname &&
  //           delivery.fireworks_name !== formData.fireworkname
  //         ) {
  //           return false;
  //         }

  //         // Filter by date range
  //         const createDate = new Date(delivery.create_at);
  //         const fromDate = formData.fromdate
  //           ? new Date(formData.fromdate)
  //           : null;
  //         const toDate = formData.todate ? new Date(formData.todate) : null;

  //         if (
  //           fromDate &&
  //           toDate &&
  //           (createDate < fromDate || createDate > toDate)
  //         ) {
  //           return false;
  //         }
  //         if (fromDate && createDate < fromDate) {
  //           return false;
  //         }
  //         if (toDate && createDate > toDate) {
  //           return false;
  //         }

  //         // Filter by product name
  //         if (formData.productsName) {
  //           const products = JSON.parse(delivery.products || "[]");
  //           const productMatch = products.some(
  //             (product) => product.productName === formData.productsName
  //           );
  //           if (!productMatch) {
  //             return false;
  //           }
  //         }

  //         return true;
  //       });

  //       // Update state with filtered data
  //       setstockData(sortedData);
  //     } else {
  //       throw new Error(responseData.message || "Unknown error");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    fetchmagazinestockData();
    //fetchproductData();
    fetchfactoryData();
    fetchproductbasedtData();
    refershdata();
  }, [formData]);
  const handleLoad = () => {
    fetchmagazinestockData();
    setFormData({
      fireworkname: "",
      productsName: "",
    });
  };
  const refershdata = () => {
    setCsvData([]);
    setShowExcelButton(true);
  };
  const generateCSV = () => {
    // Convert the aggregated data object into an array
    const aggregatedList = Object.values(aggregatedData);

    // Prepare CSV headers
    const headers = [
      "S No",
      "Product Name",
      "Unit Type",
      "Sub Unit Type",
      "Case Count",
    ];

    // Prepare CSV rows
    const rows = aggregatedList.map((item, index) => [
      index + 1, // S No
      item.productName || "Unknown", // Product Name
      item.unitType || "-", // Unit Type
      item.subUnitType || "-", // Sub Unit Type
      (item.caseCount || 0).toFixed(2), // Case Count, formatted to 2 decimal places
    ]);

    // Convert to CSV format
    const csvContent = [
      headers.join(","), // Add headers
      ...rows.map((row) => row.join(",")), // Add data rows
    ].join("\n");

    // Create a downloadable CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "stock_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function generatePDF() {
    const input = document.getElementById("table-container");
    html2canvas(input, {
      width: 1300,
      height: 1200,
      scale: 5, // Decrease scale to reduce image size
      logging: true, // Enable logging for debugging
      useCORS: true, // Enable cross-origin resource sharing
      imageTimeout: 15000, // Timeout for loading images
      allowTaint: true, // Allow taint
    })
      .then((canvas) => {
        const pdf = new jsPDF("p", "in", "letter");

        // Load company logo asynchronously
        const img = new Image();
        img.onload = function () {
          // Add company logo at the top center
          const imgWidth = 2; // Width of the logo in inches
          const imgHeight = (imgWidth / this.width) * this.height; // Maintain aspect ratio
          const logoX = (pdf.internal.pageSize.width - imgWidth) / 2; // Center horizontally
          const logoY = 0.1; // Top margin in inches
          pdf.addImage(img, "PNG", logoX, logoY, imgWidth, imgHeight);

          // Add report title below center
          let title = "Maha Vilvam Pyropark Magazine Report"; // Default title

          // Modify title based on formData
          if (
            formData.fireworkname ||
            formData.productsName ||
            formData.fromDate ||
            formData.toDate
          ) {
            title = "Maha Vilvam Pyropark Magazine Report" + "\n";
            // You can customize this based on your actual data
            const extraTitles = []; // Array to store additional titles

            if (formData.fireworkname)
              extraTitles.push(`fireworkname: ${formData.fireworkname}`);
            if (formData.productsName)
              extraTitles.push(`productsName: ${formData.productsName}`);
            if (formData.fromDate)
              extraTitles.push(`fromDate: ${formData.fromDate}`);
            if (formData.toDate) extraTitles.push(`toDate: ${formData.toDate}`);

            if (extraTitles.length > 0) {
              title += "\n(" + extraTitles.join(", ") + ")" + "\n";
            }
          }

          const titleX = pdf.internal.pageSize.width / 2;
          const titleY = 2.5; // Below logo in inches
          pdf.setFontSize(14);
          pdf.setFont("helvetica", "bold"); // Set font style to bold
          pdf.text(titleX, titleY, title, null, null, "center");

          // Add image to PDF with fixed dimensions
          pdf.addImage(
            canvas.toDataURL("image/jpeg", 2),
            "JPEG",
            0.4,
            3,
            8.2,
            10
          ); // Decrease JPEG quality to reduce file size

          // Save PDF
          pdf.save("table.pdf");
        };

        img.onerror = function () {
          console.error("Failed to load the company logo.");
        };

        img.src = logoImage; // Set the image source
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }
  console.log("stockData", stockData);
  const aggregatedData = stockData
    .flatMap((entry) => JSON.parse(entry.products)) // Parse JSON strings and flatten products
    .reduce((acc, item) => {
      const key = `${item.productName}-${item.unitType || ""}-${
        item.subUnitType || ""
      }`; // Unique key based on type
      if (acc[key]) {
        acc[key].caseCount += parseFloat(item.caseCount); // Aggregate case count
      } else {
        acc[key] = {
          productName: item.productName,
          unitType: item.unitType || null,
          subUnitType: item.subUnitType || null,
          caseCount: parseFloat(item.caseCount),
        };
      }
      return acc;
    }, {});

  // Convert object to array
  const aggregatedList = Object.values(aggregatedData);

  const TableBody = () => (
    <tbody>
      {aggregatedList.map((product, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{product.productName}</td>
          <td>
            {product.unitType
              ? `${product.caseCount.toFixed(2)} ${product.unitType}`
              : "-"}
          </td>
          <td>
            {product.subUnitType
              ? `${product.caseCount.toFixed(2)} ${product.subUnitType}`
              : "-"}
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div>
      <div className="main-content">
        <Container>
          <Row>
            <Col lg="12" className="py-4 text-end">
              <span>
                <Button onClick={handleLoad} className="filter mx-2">
                  <span className="me-2">
                    <IoFilter />
                  </span>
                  Undo Filter
                </Button>
              </span>
            </Col>
            <Col lg="12" md="12" xs="12" className="py-4">
              <div>Magazine Stock Report</div>
            </Col>

            <Col lg="3" md="6" xs="12" className="py-4">
              <DropDownUI
                optionlist={factoryData.map((item) => ({
                  label: item.factory_name,
                  value: item.factory_name,
                }))}
                placeholder="FireWorks Name"
                labelname="FireWorksName"
                name="fireworkname"
                value={formData.fireworkname}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    fireworkname: updatedFormData.fireworkname,
                  })
                }
              />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-4">
              <DropDownUI
                optionlist={productData.map((item) => ({
                  label: item.product_name,
                  value: item.product_name,
                }))}
                placeholder="Products Name"
                labelname="Products Name"
                name="productsName"
                value={formData.productsName}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    productsName: updatedFormData.productsName,
                  })
                }
              />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-4">
              <div>
                <Calender
                  setLabel={(date) => setLabel(date, "fromdate")}
                  selectedDate={formData.data}
                  onSelectDate={setDateOfBirth}
                  calenderlabel="From Date"
                />
              </div>
            </Col>
            <Col lg="3" md="6" xs="12" className="py-4">
              <div>
                <Calender
                  setLabel={(date) => setLabel(date, "todate")}
                  selectedDate={formData.data}
                  onSelectDate={setDateOfBirth}
                  calenderlabel="To Date"
                />
              </div>
            </Col>
            <Col lg="6" className="py-4">
              <div>
                {showExcelButton && (
                  <span className="mx-1">
                    {" "}
                    <ClickButton
                      onClick={generateCSV}
                      label={<>Excel</>}
                    ></ClickButton>
                  </span>
                )}
                {csvData.length > 0 && (
                  <CSVLink data={csvData} filename="magazine_stock.csv">
                    <span className="mx-1">
                      <ClickButton
                        onClick={refershdata}
                        label={<>Download</>}
                      ></ClickButton>
                    </span>
                  </CSVLink>
                )}
              </div>
            </Col>

            <Col lg="6" className="py-4">
              <div className="text-end">
                <span className="mx-1">
                  {" "}
                  <ClickButton label={<>PDF</>} onClick={generatePDF}>
                    {" "}
                    <span className="me-2">
                      <IoFilter />
                    </span>
                  </ClickButton>{" "}
                </span>
              </div>
            </Col>

            <Col lg="12" className="py-4">
              <div className="wire-table">
                <Table id="table-container">
                  <thead>
                    <tr>
                      <th rowSpan={2}>S No</th>
                      <th rowSpan={2}>Product</th>
                      <th colSpan={2} className="text-center">
                        Total
                      </th>
                    </tr>
                    <tr>
                      <th>Unit</th>
                      <td>Sub Unit</td>
                    </tr>
                  </thead>
                  <TableBody />
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MagazineStock;
