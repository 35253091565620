import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import API_DOMAIN from "../config/config";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DropDownUI } from "../components/Forms";
import { FaArrowCircleLeft } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";
import DeliverySlipPDF from "./DeliverySlipPDF";
import DeliverySlipPreview from "../pdf/DeliverySlipPreview";
const UserTablehead = [
  "No",
  "Customer Name",
  "Quantity",
  "Action",
];

const Delivery = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [factoryData, setFactoryData] = useState([]);
  const [contractorData, setcontractorData] = useState([]);
  console.log(contractorData);
  const [itemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    fireWorksName: "",
    customerName: "",
  });
  console.log(formData);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const location = useLocation();
  const { type, rowData } = location.state || {};

  const fetchfactoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listfactory",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        setFactoryData(responseData.body.factories);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
 
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/delivery.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: searchText,
          action: "listDeliveries",
        }),
      });

      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.deliveries;

        console.log('sortedData',sortedData);
        if (formData.customerName) {
          sortedData = sortedData.filter(
            (user) => user.customer_name === formData.customerName
          );
        }

        if (formData.fireWorksName) {
          sortedData = sortedData.filter(
            (user) => user.fireworks_name === formData.fireWorksName
          );
        }
        setUserData(sortedData || []);
      } else {
        throw new Error(responseData.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const handlePrint = () => {
    setShowPrintDialog(true);
  };

  const handleCancelPrint = () => {
    setShowPrintDialog(false);
  };

  const handleCancelNavigation = () => {
    setShowPrintDialog(false);
    navigate("/console/delivery");
  };
  const handleSearch = (value) => {
    setSearchText(value);
  };
  const handleLoad = () => {
    setFormData({
      fireWorksName: "",
      customerName: "",
    });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    fetchData();
   
    fetchfactoryData();
  }, [searchText, currentPage, formData, type]);

  // useEffect(() => {
  //   if (type === "pdfview") {
  //     setShowPrintDialog(true);
  //   }
  //   if (showPrintDialog && type === "pdfview") {
  //     window.print();
  //     const handleAfterPrint = () => {
  //       handleCancelNavigation();
  //       window.removeEventListener("afterprint", handleAfterPrint);
  //     };
  //     window.addEventListener("afterprint", handleAfterPrint);
  //   }
  // }, [showPrintDialog, type]);

  // if (type === "pdfview") {
  //   const selectfactory = factoryData.find(
  //     (firework) => firework.factory_name === rowData.fireworks_name
  //   );
  //   const factory_place1 = selectfactory?.place;
  //   const selectcustomer = contractorData.find(
  //     (customer) => customer.name === rowData.customer_name
  //   );
  //   const customer_place = selectcustomer?.place;
  //   const customer_ph_number = selectcustomer?.mobile_number;

  //   return (
  //     <DeliverySlipPreview
  //       data={rowData}
  //       factory_place={factory_place1}
  //       customer_place={customer_place}
  //       customer_ph_number={customer_ph_number}
  //     />
  //   );
  // }

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="6" xs="6">
            <div className="page-nav py-3">
              <span class="nav-list">Delivery</span>
            </div>
          </Col>
          <Col lg="5" md="6" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/delivery/create")}
            ></ClickButton>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Fireworks Name"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search"}
            >
              {" "}
            </TextInputForm>
          </Col>
          <Col lg={6} md={6} xs={12} className="py-2 text-end">
            <span>
              <Button onClick={handleShow} className="filter my-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Filter
              </Button>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
              className="offscreen"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Delivery Filter</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoMdCloseCircle className="iomdclose" />
                  </Button>
                  <div>
                  <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={factoryData.map((product) => ({
                          value: product.factory_name,
                          label: product.factory_name,
                        }))}
                        placeholder="FireWorks Name"
                        labelname={"FireWorks Name"}
                        name="fireWorksName"
                        value={formData.fireWorksName}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            fireWorksName: updatedFormData.fireWorksName,
                          })
                        }
                      ></DropDownUI>
                    </Col>
                    {/* <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={contractorData.map((contractor) => ({
                          value: contractor.name,
                          label: contractor.name,
                        }))}
                        placeholder="Customer Name"
                        labelname={"customer Name"}
                        name="customerName"
                        value={formData.customerName}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            customerName: updatedFormData.customerName,
                          })
                        }
                      ></DropDownUI>
                    </Col> */}
                  </Row>
                
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg={3} md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {/* {userData && userData.map((user, index) => (
                                <MobileView key={index} sno={user.id} name={user.name} subname={user.role} />
                            ))} */}
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />{" "}
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={userData}
                  type="delivery"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
        {showPrintDialog && type === "pdfview" ? (
          <div className="print-dialog">
            <div className="print-dialog-content">
              <h2>Print Document</h2>
              <p>Click "Print" to print the document or "Cancel" to go back.</p>
              <Button onClick={handlePrint}>Print</Button>
              <Button onClick={handleCancelPrint}>Cancel</Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </Container>
    </div>
  );
};

export default Delivery;
