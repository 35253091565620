import React, { useState ,useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from './components/sidebar/SideBar'
import '../src/components/sidebar/sidebar.css'
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../src/pages/Login';
import User from './pages/User';
import { BrowserRouter } from 'react-router-dom';
import Group from './pages/Group';
import Payroll from './pages/Payroll';
import ContractReport from './pages/ContractReport';
import Delivery from './pages/Delivery';
import Customer from './pages/Customer';
import FactoryCreation from './pages/FactoryCreation';
import UserCreation from './pages/UserCreation'
import CompanyCreation from './pages/CompanyCreation'
import MagazineCreation from './pages/MagazineCreation'
import StaffCreation from './pages/StaffCreation';
import ProductCreation from './pages/ProductCreation';
import ContractCreation from './pages/ContractCreation';
import CustomerCreation from './pages/CustomerCreation';
import PayrollCreation from './pages/PayrollCreation';
import DeliverySlipCreation from './pages/DeliverySlipCreation';
import DashBoard from './pages/DashBoard';
import Company from './pages/Company';
import Factory from './pages/Factory'
import Magazine from './pages/Magazine';
import Staff from './pages/Staff';
import Unit from './pages/Unit';
import Category from './pages/Category';
import Product from './pages/Product';
import Contract from './pages/Contract';
import UnitCreation from './pages/UnitCreation';
import GroupCreation from './pages/GroupCreation';
import CategoryCreation from './pages/CategoryCreation'
import UserOffcanvas from './pages/UserOffcanvas';
import MagazineStock from './pages/MagazineStock';
import DeliveryReport from './pages/DeliveryReport';
import Attendance from './pages/attendance';
import AttendanceCreation from './pages/AttendanceCreation';
import AttendanceReport from './pages/AttendanceReport';
import OrderFormReport from './pages/OrderFromreport';
import DeliverySlipPreview from './pdf/DeliverySlipPreview';
const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem('session');
    if (session) {
        setLoggedIn(true);
    } else {
        setLoggedIn(false);
    }
}, []);

  const handleLogin = () => {
    localStorage.setItem('session', 'true');
    setLoggedIn(true);
};

  const handleLogout = () => {
    localStorage.removeItem('session');
    setLoggedIn(false);
    return <Navigate to="/login" replace />;
};
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={loggedIn ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
          <Route path='/login' element={<Login onLogin={handleLogin} />}></Route>
          <Route element={<SideBar onLogout={handleLogout} />}>
            <Route path='/console/dashboard' element={<DashBoard />} ></Route>
            <Route path='/console/user' element={<User />}></Route>
            <Route path='/console/user/create' element={<UserCreation />}></Route>
            <Route path='/console/user/useroffcanvas' element={<UserOffcanvas />}></Route>
            <Route path='/console/company' element={<Company />}></Route>
            <Route path='/console/company/create' element={<CompanyCreation />}></Route>
            <Route path='/console/master/factory' element={<Factory />}></Route>
            <Route path='/console/master/factory/create' element={<FactoryCreation />}></Route>
            <Route path='/console/master/magazine' element={<Magazine />}></Route>
            <Route path='/console/master/magazine/create' element={<MagazineCreation />}></Route>
            <Route path='/console/master/staff' element={<Staff />}></Route>
            <Route path='/console/master/staff/create' element={<StaffCreation />}></Route>
            <Route path='/console/master/unit' element={<Unit />}></Route>
            <Route path='/console/master/unit/create' element={<UnitCreation />}></Route>
            <Route path='/console/master/group' element={<Group />}></Route>
            <Route path='/console/master/group/create' element={<GroupCreation />}></Route>
            <Route path='/console/master/category' element={<Category />}></Route>
            <Route path='/console/master/category/create' element={<CategoryCreation />}></Route>
            <Route path='/console/master/product' element={<Product />}></Route>
            <Route path='/console/master/product/create' element={<ProductCreation />}></Route>
            <Route path='/console/master/contract' element={<Contract />}></Route>
            <Route path='/console/master/contract/create' element={<ContractCreation />}></Route>
            <Route path='/console/master/customer' element={<Customer />}></Route>
            <Route path='/console/master/customer/create' element={<CustomerCreation />}></Route>
            <Route path='/console/payroll' element={<Payroll />}></Route>
            <Route path='/console/payroll/create' element={<PayrollCreation />}></Route>
            <Route path='/console/delivery' element={<Delivery />}></Route>
            <Route path='/console/delivery/create' element={<DeliverySlipCreation />}></Route>
            <Route path='/console/attendance' element={<Attendance />}></Route>
            <Route path='/console/attendance/create' element={<AttendanceCreation />}></Route>
            <Route path='/console/orderform' element={<Attendance />}></Route>
            <Route path='/console/orderform/create' element={<AttendanceCreation />}></Route>
            <Route path='/console/stock/magazine' element={<MagazineStock />}></Route>
            <Route path='/console/report/contract' element={<ContractReport />}></Route>
            <Route path='/console/report/delivery' element={<DeliveryReport />}></Route>
            <Route path='/console/report/attendance' element={<AttendanceReport />}></Route>
            <Route path='/console/report/orderform' element={<OrderFormReport/>}></Route>
            <Route path='/console/pdfpreview' element={<DeliverySlipPreview/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;

