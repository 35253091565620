import React from 'react'
import {  PDFViewer } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import DeliverySlip from './DeliverySlip';
const DeliverySlipPreview = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  console.log('rowData',rowData);
  return (
    <PDFViewer width="100%" height="1000">
      <DeliverySlip data={rowData} />
    </PDFViewer>
  )};
export default DeliverySlipPreview