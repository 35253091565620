import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { TextInputForm } from "../components/Forms";
import API_DOMAIN from "../config/config";
import { ClickButton } from "../components/ClickButton";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const CompanyCreation = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          edit_company_id: "",
          company_name: "",
          gst: "",
          mobile_number: "",
          address: "",
          place: "",
        };

  const [formData, setFormData] = useState(initialState);

  console.log(rowData);

  const handleInputChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleUpdateSubmit = async () => {
    try {
      console.log("Inside handleUpdateSubmit");
      setLoading(true);

      // Validate required fields
      const { company_name, mobile_number, gst, address, place } = formData;
      if (!company_name || !mobile_number || !gst || !address || !place) {
        toast.error("All fields are required!", {
          position: "top-center",
          autoClose: 2000,
        });
        setLoading(false);
        return;
      }

      // Log data before sending
      console.log("Form Data:", {
        edit_company_id: rowData.id,
        company_name,
        mobile_number,
        gst,
        address,
        place,
      });

      const response = await fetch(`${API_DOMAIN}/company.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateCompanyInfo", // Ensure action is included
          edit_company_id: rowData.id,
          company_name,
          mobile_number,
          gst,
          address,
          place,
        }),
      });

      const responseData = await response.json();
      console.log("Response Data:", responseData);

      if (responseData.head.code === 200) {
        toast.success(responseData.head.msg, { position: "top-center" });
        setTimeout(() => navigate("/console/company"), 2000);
      } else {
        throw new Error(responseData.head.msg || "Unknown error");
      }
    } catch (error) {
      console.error("Error updating company:", error.message);
      toast.error(error.message, { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    // Similar logic as handleSubmit
    setLoading(false);
    setLoading(true);
  };

  // ... (rest of the handleSubmit logic)

  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`Contract${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
            <div className="pt-3"> Company Details</div>
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Company Name"}
                labelname={"Company Name"}
                value={formData.company_name}
                onChange={(e) => handleInputChange(e, "company_name")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Company Name"}
                labelname={"Company Name"}
                value={
                  type === "view" ? rowData.company_name : formData.company_name
                }
                onChange={(e) => handleInputChange(e, "company_name")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"GST No."}
                labelname={"GST No."}
                value={formData.gst}
                onChange={(e) => handleInputChange(e, "gst")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"GST No."}
                labelname={"GST No."}
                value={type === "view" ? rowData.gst : formData.gst}
                onChange={(e) => handleInputChange(e, "gst")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Mobile No."}
                labelname={"Mobile No."}
                value={formData.mobile_number}
                onChange={(e) => handleInputChange(e, "mobile_number")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Mobile No."}
                labelname={"Mobile No."}
                value={
                  type === "view"
                    ? rowData.mobile_number
                    : formData.mobile_number
                }
                onChange={(e) => handleInputChange(e, "mobile_number")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Address"}
                labelname={"Address"}
                value={formData.address}
                onChange={(e) => handleInputChange(e, "address")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Address"}
                labelname={"Address"}
                value={type === "view" ? rowData.address : formData.address}
                onChange={(e) => handleInputChange(e, "address")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Taluk"}
                labelname={"place"}
                value={formData.place}
                onChange={(e) => handleInputChange(e, "place")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Taluk"}
                labelname={"place"}
                value={type === "view" ? rowData.place : formData.place}
                onChange={(e) => handleInputChange(e, "place")}
              ></TextInputForm>
            )}
          </Col>

          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/company")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update </>}
                          onClick={() => handleUpdateSubmit()}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/company")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Submit</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/company")}
                        ></ClickButton>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CompanyCreation;
