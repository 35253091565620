import React, { useState, useEffect } from "react";
import { Col, Container, Row, Alert, Modal } from "react-bootstrap";
import { TextInputForm, DropDownUI } from "../components/Forms";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import API_DOMAIN from "../config/config";
import {  ClickButton } from "../../src/components/ClickButton";

import PageNav from "../components/PageNav";
import { useLocation } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DropList = [
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Super Admin",
    label: "Super Admin",
  },
  {
    value: "Staff",
    label: "Staff",
  },
];

const UserCreation = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  console.log(rowData);
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          Name: "",
          RoleSelection: "",
          FireWorksName: "",
          Mobile_Number: "",
          User_Name: "",
          Password: "",
        };
  const initialStateModal =
    type === "edit"
      ? { ...rowData }
      : {
          //edit_factory_id: '',
          factory_name: "",
          place: "",
        };
  const [formDataModal, setFormDataModal] = useState(initialStateModal);
  const handleInputChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormDataModal({
      ...formDataModal,
      [fieldName]: value,
    });
  };
  console.log(formDataModal);
  const [formData, setFormData] = useState(initialState);
  console.log(formData);

  const [error, setError] = useState("");

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const redirectModal = () => {
    navigate("/console/user");
  };

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const [factoryData, setFactoryData] = useState([]);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem("session");
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking session:", error.message);
        setIsLoggedIn(false);
      }
    };

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/factory.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            action: "listfactory",
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        console.log(responseData);

        if (responseData.head.code === 200) {
          setFactoryData(responseData.body.factories);
          console.log(responseData.body.factories);
        } else {
          setFactoryData([]);
        }
      } catch (error) {
        console.error("Error fetching factory data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    checkSession();
  }, []);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    
    for (const key in formData) {
      if (formData[key] === "") {
        toast.error(`${key} cannot be empty!`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return; 
      }
    }

    const mobileNumber = formData.Mobile_Number;
    if (!/^\d{10}$/.test(mobileNumber)) {
      toast.error("Mobile number must be a 10-digit number!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return; 
    }

    try {
      const response = await fetch(`${API_DOMAIN}/users.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          action: "addusers",
        }),
      });
      console.log("aa", response);
      // Parse the response data
      const responseData = await response.json();
      console.log("bb", responseData);

      // Check if the response is successful
      if (responseData.head.code === 200) {
        toast.success("🦄 Successfully added user!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/user");
        }, 1000);
      } else {
        toast.error(responseData.head.msg || "An error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("There was an error while submitting!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleUpdateSubmit = async () => {
    console.log("Inside handleUpdateSubmit");
    setLoading(true);

    try {
      const response = await fetch(`${API_DOMAIN}/users.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          action: "updateuser", // Ensure the action is correct
        }),
      });
      console.log("response", response);

      if (!response.ok) {
        throw new Error("Failed to update user");
      }

      const responseData = await response.json();
      console.log("Response Data:", responseData);

      if (responseData.head.code === 200) {
        toast.success("User updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // Navigate to the user list page after a delay
        setTimeout(() => {
          navigate("/console/user");
        }, 2000);
      } else {
        console.error(
          responseData.head.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating user:", error.message);
    }

    setLoading(false);
  };

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  const saveFactoryName = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          factory_name: formDataModal.factory_name,
          place: formDataModal.place,
        }),
      });
      const responseData = await response.json();

      console.log(responseData);
      console.log(
        JSON.stringify({
          factory_name: formDataModal.factory_name,
          place: formDataModal.place,
        })
      );
      if (responseData.status === 200) {
        toast.success("Factory Name Instand Data Successfully Added!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {}, 1000);
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleModalSubmit = () => {
    // Update factoryData state with the new value entered in the modal
    const newFactoryName = formDataModal.factory_name;
    setFactoryData((prevState) => [
      ...prevState,
      { factory_name: newFactoryName },
    ]);
    setFormData((prevState) => ({
      ...prevState,
      FireWorksName: newFactoryName,
    }));
    handleCloseModal();
    saveFactoryName();
  };
  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`User${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>

          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Name"}
                labelname={"Name"}
                name="Name"
                value={formData.Name}
                onChange={(e) => handleChange(e, "Name")}
              />
            ) : (
              <TextInputForm
                placeholder={"Name"}
                labelname={"Name"}
                name="Name"
                value={type === "view" ? rowData.Name : formData.Name}
                onChange={(e) => handleChange(e, "Name")}
              />
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <DropDownUI
                optionlist={DropList}
                placeholder="Role Selection"
                labelname="Role Selection"
                name="RoleSelection"
                value={formData.RoleSelection}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    RoleSelection: updatedFormData.RoleSelection,
                  })
                }
              />
            ) : (
              <DropDownUI
                optionlist={DropList}
                placeholder="Role Selection"
                labelname="Role Selection"
                name="RoleSelection"
                value={
                  type === "view"
                    ? rowData.RoleSelection
                    : formData.RoleSelection
                }
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    RoleSelection: updatedFormData.RoleSelection,
                  })
                }
              />
            )}
          </Col>
          {/* <Col lg='2' md='4' xs='12' className='py-3'>
            <TextInputForm
              placeholder={'Name'}
              labelname={'Name'}
              name='Name'
              value={formData.Name}
              onChange={(e) => handleChange(e, 'Name')}
            ></TextInputForm>
          </Col> */}
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <div>
                <DropDownUI
                  optionlist={factoryData.map((factory) => ({
                    value: factory.factory_name,
                    label: factory.factory_name,
                  }))}
                  labelname={"Fireworks Name"}
                  placeholder="Fireworks Name"
                  name="FireWorksName"
                  value={ formData.FireWorksName 
                    }
                  onChange={(updatedFormData) =>
                    setFormData({
                      ...formData,
                      FireWorksName: updatedFormData.value,
                    })
                  }
                />
              </div>
            ) : (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={factoryData.map((factory) => ({
                      value: factory.factory_name,
                      label: factory.factory_name,
                    }))}
                    labelname={"Fireworks Name"}
                    placeholder="Fireworks Name"
                    name="FireWorksName"
                    value={
                      
                        type === "view"
                          ? rowData.FireWorksName
                          : formData.FireWorksName  // Fallback to empty string
                      }
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        FireWorksName: updatedFormData.FireWorksName,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </Col>

          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Mobile Number"}
                type={"number"}
                labelname={"Mobile Number"}
                name="Mobile_Number"
                value={formData.Mobile_Number}
                onChange={(e) => handleChange(e, "Mobile_Number")}
              />
            ) : (
              <TextInputForm
                placeholder={"Mobile Number"}
                type={"number"}
                labelname={"Mobile Number"}
                name="Mobile_Number"
                value={
                  type === "view"
                    ? rowData.Mobile_Number
                    : formData.Mobile_Number
                }
                onChange={(e) => handleChange(e, "Mobile_Number")}
              />
            )}
          </Col>

          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"User Name"}
                labelname={"User Name"}
                name="User_Name"
                value={formData.User_Name}
                onChange={(e) => handleChange(e, "User_Name")}
              />
            ) : (
              <TextInputForm
                placeholder={"User Name"}
                labelname={"User Name"}
                name="User_Name"
                value={type === "view" ? rowData.User_Name : formData.User_Name}
                onChange={(e) => handleChange(e, "User_Name")}
              />
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "view" ? null : (
              <TextInputForm
                placeholder={"Password"}
                suffix_icon={
                  showPassword ? (
                    <VscEye onClick={() => setShowPassword(false)} />
                  ) : (
                    <VscEyeClosed onClick={() => setShowPassword(true)} />
                  )
                }
                labelname={"Password"}
                type={showPassword ? "text" : "password"}
                name="Password"
                value={formData.Password}
                onChange={(e) => handleChange(e, "Password")}
              />
            )}
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/user")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>

                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/user")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <div className="d-flex">
                      <span className="mx-2">
                        <ClickButton
                          label={<> Submit</>}
                          onClick={handleSubmit}
                          disabled={loading}
                        ></ClickButton>
                        <ToastContainer
                          position="top-center"
                          autoClose={2000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="colored"
                        />
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/user")}
                        ></ClickButton>
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {error && (
          <Alert variant="danger" className="error-alert">
            {error}
          </Alert>
        )}
      </Container>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className="text-center">
          <img
            src={require("../components/sidebar/images/output-onlinegiftools.gif")}
            alt="Success GIF"
          />
          <p>User saved successfully!</p>
        </Modal.Body>
        <Modal.Footer>
          <ClickButton
            variant="secondary"
            label={<> Close</>}
            onClick={() => redirectModal()}
          >
            Close
          </ClickButton>
        </Modal.Footer>
      </Modal>
      <div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInputForm
              placeholder={"Factory Name"}
              labelname={"Factory Name"}
              name="factory_name"
              value={formDataModal.factory_name}
              onChange={(e) => handleInputChange(e, "factory_name")}
            ></TextInputForm>

            <TextInputForm
              placeholder={"Place"}
              labelname={"Place"}
              name="place"
              value={formDataModal.place}
              onChange={(e) => handleInputChange(e, "place")}
            ></TextInputForm>
          </Modal.Body>
          <Modal.Footer>
            <ClickButton label={<>Cancel</>} onClick={handleCloseModal}>
              Close
            </ClickButton>
            <ClickButton label={<>Submit</>} onClick={handleModalSubmit}>
              Save Changes
            </ClickButton>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UserCreation;
