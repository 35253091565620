import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PageNav from '../components/PageNav'
import { TextInputForm, DropDown } from '../components/Forms'
import { VscEyeClosed } from "react-icons/vsc";
import { ClickButton } from '../components/ClickButton';

const DropList = [
    {
        value: "TN",
        title: "Tamil Nadu"
    },
    {
        value: "PY",
        title: "Pondycheri"
    },
    {
        value: "KL",
        title: "Kerala"
    }, {
        value: "TL",
        title: "Telugana"
    }
];
const StaffCreation = () => {
    return (
        <div className='main-content'>
            <Container>
                <Row className='regular'>
                    <Col lg='12' md='12' xs='12' className='py-3'>
                        <PageNav pagetitle={" Staff Creation"}></PageNav>
                    </Col>
                    <Col lg='3' md='4' xs='12' className='py-3'>
                        <TextInputForm placeholder={"Staff Name"} labelname={"Staff Name"}></TextInputForm>
                    </Col>
                    <Col lg='2' md='4' xs='12' className='py-3'>
                        <TextInputForm placeholder={"Mobile Number"} labelname={"Mobile Number"}></TextInputForm>
                    </Col>
                    <Col lg='3' md='4' xs='12' className='py-3'>
                    <TextInputForm placeholder={"Role"} labelname={"Role"}></TextInputForm>
                    </Col>
                    <Col lg='4' md='4' xs='12' className='py-3'>
                        <TextInputForm placeholder={"Place"} labelname={"Place"}></TextInputForm>
                    </Col>
                    <Col lg='12'>
                        <div className='pt-5  mb-3 text-center'>
                            <ClickButton label={<> Submit</>}></ClickButton>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StaffCreation