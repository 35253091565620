import React, { useState } from 'react'
import { Route, Routes, NavLink, useNavigate } from 'react-router-dom';
import DashBoard from '../../pages/DashBoard';
import User from '../../pages/User'
import Company from '../../pages/Company';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { LuDot } from "react-icons/lu";
import { FaSquarePlus } from "react-icons/fa6";
import { Button, Collapse } from 'react-bootstrap';
import Factory from '../../pages/Factory'
import Magazine from '../../pages/Magazine';
import Staff from '../../pages/Staff';
import Unit from '../../pages/Unit';
import Category from '../../pages/Category';
import CategoryCreation from '../../pages/CategoryCreation'
import Product from '../../pages/Product';
import Contract from '../../pages/Contract';
import { TbTruckDelivery } from "react-icons/tb";
import { BsBoxes } from "react-icons/bs";
import { MdOutlineDashboard, MdOutlinePerson, MdOutlineStorefront,MdOutlineAddchart,MdOutlineAppRegistration ,MdAssignmentAdd  } from "react-icons/md";
import Group from '../../pages/Group';
import GroupCreation from '../../pages/GroupCreation'
import Payroll from '../../pages/Payroll';
import ContractReport from '../../pages/ContractReport';
import Delivery from '../../pages/Delivery';
import Customer from '../../pages/Customer';
import FactoryCreation from '../../pages/FactoryCreation';
import UserCreation from '../../pages/UserCreation'
import CompanyCreation from '../../pages/CompanyCreation'
import MagazineCreation from '../../pages/MagazineCreation'
import StaffCreation from '../../pages/StaffCreation';
import ProductCreation from '../../pages/ProductCreation';
import ContractCreation from '../../pages/ContractCreation';
import CustomerCreation from '../../pages/CustomerCreation';
import PayrollCreation from '../../pages/PayrollCreation';
import DeliverySlipCreation from '../../pages/DeliverySlipCreation';
import { BsBoxArrowRight } from "react-icons/bs";
import UnitCreation from '../../pages/UnitCreation';
import UserOffcanvas from '../../pages/UserOffcanvas';
import MagazineStock from '../../pages/MagazineStock';
import DeliveryReport from '../../pages/DeliveryReport';
import AttendanceCreation from '../../pages/AttendanceCreation';
import Attendance from '../../pages/attendance';
import OrderForm from '../../pages/OrderForm';
import OrderFormCreation from '../../pages/OrderFormCreation';
import OrderFormReport from '../../pages/OrderFromreport';
import AttendanceReport from '../../pages/AttendanceReport';
import DeliverySlipPreview from '../../pdf/DeliverySlipPreview';

const SideBar = ({ onLogout }) => {

  const [open, setOpen] = useState(null);
  const [isDeactive, SetDeactive] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };
  const toggle = () => { SetDeactive(!isDeactive ? 'remove' : ''); };
  return (
    <>
      <div className={` ${isDeactive ? "wrap-remove" : ""}`} id="sidebar-wrapper">
        <div className="list-group regular">
          <ul>
            <li>
              <div className='user-logo mx-auto'>
                <img src={require('../sidebar/images/logo.jpeg')} className='img-fluid logo' alt="" />
              </div>
            </li>
            <li>
              <NavLink to="/console/dashboard" className="nav-link " >
                <span className="list-icon"><MdOutlineDashboard /></span>
                <span class="list-text">Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/user" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdOutlinePerson /></span>
                <span class="list-text">User</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/company" className="nav-link" activeClassName='active-link' onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdOutlineStorefront /></span>
                <span class="list-text">Company</span>
              </NavLink>
            </li>
            <li>
              <div className="sub-menu nav-link" onClick={() => setOpen(open === 1 ? null : 1)}>
                <span className="list-icon"><FaSquarePlus /></span>
                <span class="list-text"> Master</span>
                <span className="list-icon ps-3 arrow"><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={open === 1 ? true : false}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to="/console/master/factory" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Factory</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/magazine" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Magazine</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/console/master/staff" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Staff</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/console/master/unit" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Unit</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/group" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Group</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/category" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Category</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/product" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Product</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/contract" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Contractor</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/console/master/customer" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Party</span>
                    </NavLink>
                  </li> */}
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink to="/console/payroll" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdOutlineAddchart /></span>
                <span class="list-text">payroll</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/console/attendance" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdOutlineAppRegistration  /></span>
                <span class="list-text">Attendance</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/orderform" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdAssignmentAdd /></span>
                <span class="list-text">OrderForm</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/console/delivery" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><TbTruckDelivery  /></span>
                <span class="list-text">Delivery</span>
              </NavLink>
            </li>
            <li>
              <div className="sub-menu nav-link" onClick={() => setOpen(open === 3 ? null : 3)}>
                <span className="list-icon"><BsBoxes/></span>
                <span class="list-text"> Stock</span>
                <span className="list-icon ps-3 arrow"><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={open === 3 ? true : false}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to="/console/stock/magazine" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Magazine</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <div className="sub-menu nav-link" onClick={() => setOpen(open === 2 ? null : 2)}>
                <span className="list-icon"><FaSquarePlus /></span>
                <span class="list-text"> Reports</span>
                <span className="list-icon ps-3 arrow"><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={open === 2 ? true : false}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to="/console/report/contract" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Contractor</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/report/delivery" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Delivery</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/console/report/attendance" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Attendance</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/report/orderform" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">OrderForm</span>
                    </NavLink>
                  </li> */}
                </ul>
              </Collapse>
            </li>
          </ul>
        </div>
        <div className='log-out'>
          <div className='list-group'>
            <ul>
              <li>
                <Button onClick={handleLogout}>
                  <span className="list-icon"><BsBoxArrowRight /></span>
                  <span className="list-text">Logout</span>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* main-content start */}
      <div className="navbar navbar-expand  px-lg-4 header">
        <div className='d-lg-block d-none'>
          <Button className="menu-toggle" onClick={toggle} id="menu-toggle" >
            <span class="navbar-toggler-icon"></span>
          </Button>
        </div>
        <div className='d-block d-lg-none ms-auto'>
          <Button className="menu-toggle" onClick={toggle} id="menu-toggle">
            <span class="navbar-toggler-icon"></span>
          </Button>
        </div>
        <div className='d-lg-block d-none ms-auto'>
          <NavLink className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </NavLink>
        </div>
      </div>
      <div id="page-content-wrapper" className={`${isDeactive ? "page-remove" : ""}`}>
        <div className='content-bg'>
          <div className="px-4 py-4">
            <div>
              <Routes>
                <Route path='/console/dashboard' element={<DashBoard />} ></Route>
                <Route path='/console/user' element={<User />}></Route>
                <Route path='/console/user/create' element={<UserCreation />}></Route>
                <Route path='/console/user/useroffcanvas' element={<UserOffcanvas />}></Route>
                <Route path='/console/company' element={<Company />}></Route>
                <Route path='/console/company/create' element={<CompanyCreation />}></Route>
                <Route path='/console/master/factory' element={<Factory />}></Route>
                <Route path='/console/master/factory/create' element={<FactoryCreation />}></Route>
                <Route path='/console/master/magazine' element={<Magazine />}></Route>
                <Route path='/console/master/magazine/create' element={<MagazineCreation />}></Route>
                <Route path='/console/master/staff' element={<Staff />}></Route>
                <Route path='/console/master/staff/create' element={<StaffCreation />}></Route>
                <Route path='/console/master/unit' element={<Unit />}></Route>
                <Route path='/console/master/unit/create' element={<UnitCreation />} ></Route>
                <Route path='/console/master/group' element={<Group />}></Route>
                <Route path='/console/master/group/create' element={<GroupCreation />}></Route>
                <Route path='/console/master/category' element={<Category />}></Route>
                <Route path='/console/master/category/create' element={<CategoryCreation />}></Route>
                <Route path='/console/master/product' element={<Product />}></Route>
                <Route path='/console/master/product/create' element={<ProductCreation />}></Route>
                <Route path='/console/master/contract' element={<Contract />}></Route>
                <Route path='/console/master/contract/create' element={<ContractCreation />}></Route>
                <Route path='/console/master/customer' element={<Customer />}></Route>
                <Route path='/console/master/customer/create' element={<CustomerCreation />}></Route>
                <Route path='/console/payroll' element={<Payroll />}></Route>
                <Route path='/console/payroll/create' element={<PayrollCreation />}></Route>
                <Route path='/console/delivery' element={<Delivery />}></Route>
                <Route path='/console/delivery/create' element={<DeliverySlipCreation />}></Route>
                <Route path='/console/attendance' element={<Attendance/>}></Route>
                <Route path='/console/attendance/create' element={<AttendanceCreation />}></Route>
                <Route path='/console/orderform' element={<OrderForm/>}></Route>
                <Route path='/console/orderform/create' element={<OrderFormCreation />}></Route>
                <Route path='/console/stock/magazine' element={<MagazineStock />}></Route>
                <Route path='/console/report/contract' element={<ContractReport />}></Route>
                <Route path='/console/report/delivery' element={<DeliveryReport />}></Route>
                <Route path='/console/report/attendance' element={<AttendanceReport />}></Route>
                <Route path='/console/pdfpreview' element={<DeliverySlipPreview />}></Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
      {/* main-content-end */}
    </>
  )
}

export default SideBar