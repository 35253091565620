import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { DropDownUI, TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { useNavigate } from "react-router-dom";
import API_DOMAIN from "../config/config";
import MobileView from "../components/MobileView";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DropDown } from "../components/Forms";
import { FaArrowCircleLeft } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { IoFilter } from "react-icons/io5";

const UserTablehead = [
  "No",
  "Magazine Name",
  "Factory Name",
  "Place",
  "Action",
];

const Magazine = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this value according to your requirement
  const [userData, setUserData] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    magazine_name: "",
    place: "",
  });
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);

  const handleLoad = () => {
    setFormData({
      magazine_name: "",
      place: "",
    });
  };
  const handleShow = () => setShow(true);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  console.log("lll", formData);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/magazine.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, action: "listMagazineStock" }),
      });

      const responseData = await response.json();
      console.log("a", responseData);

      if (responseData.head && responseData.head.code === 200) {
        let sortedData = responseData.body.magazineStocks || [];

        // Filter by searchText
        if (searchText) {
          sortedData = sortedData.filter((magazine) =>
            magazine.magazine_name
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        }

        // Filter by magazine_name if it's in formData
        if (formData.magazine_name) {
          sortedData = sortedData.filter(
            (magazine) => magazine.magazine_name === formData.magazine_name
          );
        }

        // Filter by place if it's in formData
        if (formData.place) {
          sortedData = sortedData.filter(
            (magazine) => magazine.place === formData.place
          );
        }

        setUserData(sortedData);
      } else {
        console.error(responseData.head?.msg || "Error fetching magazines");
        setUserData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setUserData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchText, currentPage, formData]);

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="12" md="12" xs="12">
            <div className="page-nav py-3">
              <span className="nav-list">Magazine</span>
            </div>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Magazine Name"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search"}
            />
          </Col>
          <Col lg={6} md={6} xs={12} className="py-2 text-end">
            <span>
              <Button onClick={handleShow} className="filter my-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Filter
              </Button>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
              className="offscreen"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoMdCloseCircle className="iomdclose" />
                  </Button>
                  <div>
                    <Button onClick={handleLoad} className="filter mx-2">
                      <span className="me-2">
                        <IoFilter />
                      </span>
                      Undo Filter
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={userData.map((magazine) => ({
                          value: magazine.magazine_name,
                          label: magazine.magazine_name,
                        }))}
                        placeholder="MagazineName"
                        labelname="MagazineName"
                        name="magazine_name"
                        value={formData.magazine_name}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            magazine_name: updatedFormData.magazine_name,
                          })
                        }
                      />
                    </Col>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={userData.map((magazine) => ({
                          value: magazine.place,
                          label: magazine.place,
                        }))}
                        labelname={"Place"}
                        placeholder="Place"
                        name="place"
                        value={formData.place}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            place: updatedFormData.place,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg={3} md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={userData}
                  type="magazine"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>

          <Col lg={12} md={12} xs={12}>
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Magazine;
