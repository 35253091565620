import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { TextInputForm } from "../components/Forms";
import { useNavigate } from "react-router-dom";
import API_DOMAIN from "../config/config";
import { useLocation } from "react-router-dom";
import { ClickButton } from "../components/ClickButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FactoryCreation = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { type, rowData } = location.state || {};
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          //edit_factory_id: '',
          factory_name: "",
          place: "",
        };

  const [formData, setFormData] = useState(initialState);
  const handleInputChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    for (const key in formData) {
      if (formData[key] === "") {
        toast.error(`${key} cannot be empty!`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        return; // Exit the function early if any field is empty
      }
    }

    try {
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "addFactory",
          factory_name: formData.factory_name,
          place: formData.place,
        }),
      });

      const responseData = await response.json();
      console.log("API Response:", responseData); // Debugging log

      if (responseData.head.code === 200) {
        toast.success("Factory added successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/factory");
        }, 2000);
      } else {
        toast.error(responseData.head.msg || "An error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error adding factory", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
    setLoading(false);
  };

  const handleUpdateSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateFactoryInfo",
          edit_factory_id: rowData.id,
          factory_name: formData.factory_name,
          place: formData.place,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("API Response:", responseData); // Log the response to see what the server sends back

      if (responseData.head.code === 200) {
        toast.success("Factory Updated Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        setLoading(false);
        navigate("/console/master/factory");
      } else {
        toast.error(responseData.head.msg || "An error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating factory:", error);
      toast.error("Error updating factory", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  return (
    <div className="main-content">
      <Container>
        <Row className="regular justify-content-center">
          <Col lg="12" md="12" xs="12" className="py-3 d-inline">
            <PageNav
              pagetitle={`Factory${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Factory Name"}
                labelname={"Factory Name"}
                value={formData.factory_name}
                onChange={(e) => handleInputChange(e, "factory_name")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Factory Name"}
                labelname={"Factory Name"}
                value={
                  type === "view" ? rowData.factory_name : formData.factory_name
                }
                onChange={(e) => handleInputChange(e, "factory_name")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Place"}
                labelname={"Place"}
                value={formData.place}
                onChange={(e) => handleInputChange(e, "place")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Place"}
                labelname={"Place"}
                value={type === "view" ? rowData.place : formData.place}
                onChange={(e) => handleInputChange(e, "place")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/master/factory")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/master/factory")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Submit</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/master/factory")}
                        ></ClickButton>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {/* {loading ? formData : <Spinner animation="border" />} */}
      </Container>
    </div>
  );
};

export default FactoryCreation;
