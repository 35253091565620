import React from 'react';
import { Button } from 'react-bootstrap';
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import '../App.css';

const Pagination = ({ currentPage, itemsPerPage, totalItems, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const startItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const endItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <div className='navbar'>
      <div className='ms-auto'>
        <span className='role'> Viewing <span className='fw-bolder'>{startItemIndex}-{endItemIndex}</span> of <span className='fw-bolder'>{totalItems}</span></span>
        <Button onClick={handlePrevPage} className='page-nav-link' disabled={currentPage === 1}><MdChevronLeft /></Button>
        <Button onClick={handleNextPage} className='page-nav-link' disabled={currentPage === totalPages}><MdChevronRight /></Button>
      </div>
    </div>
  );
};

export default Pagination;
