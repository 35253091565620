import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { DropDownUI, TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import API_DOMAIN from "../config/config";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMdCloseCircle } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const UserTablehead = ["No", "Party Name", "Mobile Number", "Action"];
const Customer = () => {
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [formData, setFormData] = useState({ CustomerName: "", Place: "" });
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLoad = () => {
    setFormData({ CustomerName: "", Place: "" });
    setSearchText("");
  };
  console.log("formdata", formData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/customer.php`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            search_text: searchText,
            action: "listCustomers",
          }),
        });
        setLoading(false);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        if (responseData.head.code === 200) {
          setUserData(responseData.body.customers || []);
          setFilteredData(responseData.body.customers || []);
        } else {
          throw new Error(responseData.head.msg || "Unknown error occurred");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [searchText]);

  useEffect(() => {
    let filtered = userData;

    if (formData.CustomerName) {
      filtered = filtered.filter((user) => user.name === formData.CustomerName);
    }

    if (formData.Place) {
      filtered = filtered.filter((user) => user.place === formData.Place);
    }

    setFilteredData(filtered);
  }, [formData, userData]);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col xs="12">
            <div className="page-nav py-3">
              <span className="nav-list">Customer</span>
            </div>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-1">
            <TextInputForm
              placeholder="Customer Name"
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname="Search"
            />
          </Col>
          <Col lg="9" md="9" xs="12" className="text-end py-2 align-self-center">
            <Button onClick={handleShow} className="filter">
              <IoFilter className="me-2" />
              Filter
            </Button>
            <Button onClick={handleLoad} className="filter mx-2">
              Undo Filter
            </Button>
            <Offcanvas show={show} onHide={handleClose} placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filters</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoMdCloseCircle className="iomdclose" />
                  </Button>
                  <div>
                    <Button onClick={handleLoad} className="filter mx-2">
                      <span className="me-2">
                        <IoFilter />
                      </span>
                      Undo Filter
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={userData.map((customer) => ({
                          value: customer.name,
                          label: customer.name,
                        }))}
                        placeholder="Customer Name"
                        labelname="Customer Name"
                        name="CustomerName"
                        value={formData.CustomerName}
                        onChange={(selectedValue) =>
                          setFormData({
                            ...formData,
                            CustomerName: selectedValue.CustomerName,
                          })
                        }
                      />
                      <DropDownUI
                        optionlist={userData.map((factory) => ({
                          value: factory.place,
                          label: factory.place,
                        }))}
                        labelname={"Place"}
                        placeholder="Place"
                        name="Place"
                        value={formData.Place}
                        onChange={(selectedValue) =>
                          setFormData({
                            ...formData,
                            Place: selectedValue.Place,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg="12" className="px-0">
            {loading ? (
              <Spinner animation="border" variant="dark" />
            ) : (
              <TableUI
                headers={UserTablehead}
                body={paginatedData}
                type="customer"
              />
            )}
          </Col>
          <Col lg="12">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Customer;
