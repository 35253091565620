import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { ClickButton } from "../components/ClickButton";
import { DropDown, TextInputForm } from "../components/Forms";
import { MdDeleteOutline } from "react-icons/md";
const OrderFormCreation = () => {
  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav pagetitle={"OrderForm Creation"}></PageNav>
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <TextInputForm placeholder={"Party Name"} labelname={"Party Name"} />
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder={"Mobile Number"}
              labelname={"Mobile Number"}
            ></TextInputForm>
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <div> Address</div>
            <textarea className="form-cntrl w-100"></textarea>
          </Col>
          <Col xs="8" className="py-3 mx-auto">
            <Table>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <DropDown
                      placeholder={"Product Name"}
                      labelname={"Product Name"}
                    />
                  </td>
                  <td>
                    <div className="w-50">
                      <TextInputForm labelname="Count" />
                    </div>
                  </td>
                  <td>
                    <div className="w-50">
                      <MdDeleteOutline size={22}/>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>

          <Col xs="12" className="py-3 text-center">
            <ClickButton label="Submit" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderFormCreation;
