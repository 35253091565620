import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form } from "react-bootstrap";

import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { DropDownUI, TextInputForm } from "../components/Forms";
import API_DOMAIN from "../config/config";
import Pagnation from "../components/Pagnation";

import { ClickButton } from "../components/ClickButton";

import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DropDown } from "../components/Forms";
import { FaArrowCircleLeft } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import MobileView from "../components/MobileView";
const UserTablehead = [
  "No",
  "Contract Name",
  "Factory Name",
  "Payroll Total",
  "Action",
];

const Payroll = () => {
  const [userData, setUserData] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [factoryData, setFactoryData] = useState([]);
  const [contractorData, setcontractorData] = useState([]);
  console.log('contractorData',contractorData);
  const [itemsPerPage] = useState(10);
  const [formData, setFormData] = useState({
    fireWorksName: "",
    contractorName: "",
  });
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const fetchfactoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listfactory",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.head.code === 200 && responseData.head.msg === "Success") {
        setFactoryData(responseData.body.factories);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchDatacon = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listContractors",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.status === 200 && responseData.message === "Success") {
        let sortedData = responseData.data;

        setcontractorData(sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/payroll.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action : "listPayrolls",
            search_text: searchText,
          }),
        });

        setLoading(false);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();

        if (responseData.head.code === 200) {
          let sortedData = responseData.body.payrolls;
          console.log(sortedData);

          if (formData.contractorName) {
            sortedData = sortedData.filter(
              (user) => user.contractor_name === formData.contractorName
            );
          }

          if (formData.fireWorksName) {
            sortedData = sortedData.filter(
              (user) =>
                user.fireworks_name === formData.fireWorksName
            );
          }
          setUserData(sortedData);
        } else {
          throw new Error(responseData.message || "Unknown error occurred");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchData();
    fetchfactoryData();
    fetchDatacon();
  }, [searchText, currentPage, formData]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  console.log(userData);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearch = (value) => {
    setSearchText(value);
    //console.log(value);
    setCurrentPage(1);
  };
  const handleLoad = () => {
   setFormData({
    fireWorksName: "",
    contractorName: "",
   });
  };
  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="6" xs="6">
            <div className="page-nav py-3">
              <span class="nav-list">PayRoll</span>
            </div>
          </Col>
          <Col lg="5" md="6" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/payroll/create")}
            ></ClickButton>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-1">
            <TextInputForm
              placeholder={"Contractor Name"}
              onChange={(e) => handleSearch(e.target.value)}
              prefix_icon={<FaMagnifyingGlass />}
              labelname={"Search"}
            >
              {" "}
            </TextInputForm>
          </Col>
          <Col lg={6} md={6} xs={12} className="py-2 text-end ">
            <span>
              <Button onClick={handleShow} className="filter my-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Filter
              </Button>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
            <Offcanvas
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={true}
              className="offscreen"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="text-end">
                  <Button onClick={handleClose}>
                    <IoMdCloseCircle className="iomdclose" />
                  </Button>
                  <div><Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button></div>
                </div>
                <div className="mt-3">
                  <Row>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={factoryData.map((product) => ({
                          value: product.factory_name,
                          label: product.factory_name,
                        }))}
                        placeholder="FireWorks Name"
                        labelname={"FireWorks Name"}
                        name="fireWorksName"
                        value={formData.fireWorksName}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            fireWorksName: updatedFormData.fireWorksName,
                          })
                        }
                      ></DropDownUI>
                    </Col>
                    <Col lg="12" md="12" xs="12" className="py-3">
                      <DropDownUI
                        optionlist={contractorData.map((contractor) => ({
                          value: contractor.contractor_name,
                          label: contractor.contractor_name,
                        }))}
                        placeholder="Contractor Name"
                        labelname={"contractor Name"}
                        name="contractorName"
                        value={formData.contractorName}
                        onChange={(updatedFormData) =>
                          setFormData({
                            ...formData,
                            contractorName: updatedFormData.contractorName,
                          })
                        }
                      ></DropDownUI>
                    </Col>
                  </Row>
                  {/* <div className='mt-5'>
                    <span className='mx-2'>
                      <ClickButton label={<> Submit</>} onClick={handleSubmit} disabled={loading}></ClickButton>
                    </span>
                    <span className='mx-2'>
                      <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                    </span>
                  </div> */}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <Col lg={3} md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {/* {userData && userData.map((user, index) => (
                <MobileView key={index} sno={user.id} name={user.contractor_name} subname={user.fireworks_name} amount={user.total} />
              ))} */}
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />{" "}
                </center>
              ) : (
                <TableUI
                  headers={UserTablehead}
                  body={userData}
                  type="payroll"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>

          <Col lg={12} md={12} xs={12}>
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={userData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Payroll;
