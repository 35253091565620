import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import fontBold from "./fonts/NotoSansTamil-Bold.ttf";
import fontRegular from "./fonts/NotoSansTamil-Regular.ttf";
import logo from "./images/logo.png";
import API_DOMAIN from "../config/config";
// Registering the fonts
Font.register({
  family: "NotoSansTamil",
  fonts: [
    { src: fontRegular, fontWeight: "normal" },
    { src: fontBold, fontWeight: "bold" },
  ],
});

// Styles for the PDF
const styles = StyleSheet.create({
  // default css start
  page: {
    padding: 10,
    fontFamily: "NotoSansTamil",
    position: "relative",
  },
  section: {
    margin: 10,
    flexGrow: 1,
    border: 1,
    padding: 0,
    borderColor: "#000",
    position: "relative",
  },
  textCenter: {
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
  alignSelf: { alignSelf: "center" },
  // default css end
  // dynamic css start
  title: {
    fontSize: 13,
    textAlign: "center",
    fontWeight: "bold",
    paddingTop: 5,
    border: 1,
    borderColor: "#000",
    width: "40%",
    borderRadius: 4,
    position: "absolute",
    top: -10,
    right: 108,
    backgroundColor: "#fff",
    margin: "0 auto",
  },
  address: {
    marginTop: 15,
    textAlign: "center",
    borderBottom: 1,
  },
  godTitle: {
    fontSize: 8,
    paddingVertical: 3,
  },
  companyName: {
    fontSize: 14,
  },
  CompanyAddress: {
    fontSize: 9,
  },
  billRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: 1,
    padding: 4,
  },
  billCol: {
    width: "50%",
  },
  billCell: {
    paddingHorizontal: 20,
    fontSize: 10,
    fontWeight: "bold",
  },
  // product table css start
  productTable: {
    display: "table",
    borderColor: "#000",
    width: "100%",
  },
  productTableRow: {
    display: "table-row",
    flexDirection: "row",
    width: "100%",
  },
  tableHeaderCell: {
    display: "table-cell",
    fontWeight: "bold",
    borderColor: "#000",
    textAlign: "center",
    fontSize: 10,
  },
  tableHeaderSubCell: {
    display: "table-cell",
    fontWeight: "bold",
  },
  totalCaseRow: {
    position: "absolute",
    bottom: 90, // Adjust to place it just above the footer
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    fontWeight: "bold",
  },
  tableCell: {
    display: "table-cell",
    fontSize: 10,
    paddingHorizontal: 6,
  },
  tableSubCell: {
    display: "table-cell",
  },
  borderRight: {
    borderRight: 1,
  },
  borderBottom: {
    borderBottom: 1,
  },
  sNo: {
    width: "10%",
  },
  product: {
    width: "50%",
  },
  qty: {
    width: "25%",
  },
  mainQty: {
    width: "50%",
    textAlign: "center",
  },
  subQty: {
    width: "50%",
    textAlign: "center",
  },
  mainQtyCount: {
    width: "50%",
    margin: "0 auto",
    paddingLeft: 20,
    paddingRight: 20,
  },
  subQtycount: {
    width: "50%",
    margin: "0 auto",
    paddingLeft: 20,
    paddingRight: 20,
  },
  total: {
    width: "15%", // Adjust the width to match alignment with other columns
    textAlign: "center", // Center the content
    paddingHorizontal: 4, // Add slight padding for better readability
    borderLeft: 1, // Add a left border for consistent column separation
  },

  padTop: {
    paddingTop: 8,
  },
  // footer css start
  footer: {
    position: "absolute",
    bottom: 8,
    left: 0,
    right: 0,
    fontSize: 10,
    borderTop: 1,
    paddingTop: 8,
    marginBottom: 50,
  },
  footerRow: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  footerCol: {
    width: "50%",
  },
  footerCell: {
    paddingHorizontal: 10,
    fontSize: 10,
    fontWeight: "bold",
  },
  footercontent: {
    position: "absolute",
    bottom: -53,
    textAlign: "center",
    left: 0,
    right: 0,
    fontWeight: "bold",
    fontSize: 9,
  },
  watermark: {
    position: "absolute",
    width: 200,
    height: 200,
    opacity: 0.3,
    left: "25%",
    top: "35%",
    pointerEvents: "none",
  },
  // dynamic css end
});

// Footer component
const Footer = () => (
  <View style={styles.footer}>
    <View style={styles.footerRow}>
      <View style={styles.footerCol}>
        <Text style={styles.footerCell}>Party Signature</Text>
      </View>
      <View style={styles.footerCol}>
        <Text style={[styles.footerCell, styles.textCenter]}>
          For Mahavilvam Pyrotech
        </Text>
      </View>
    </View>
    <Text style={styles.footercontent}>
      Thank you for your business! | Mahavilvam Pyrotech
    </Text>
  </View>
);
const Watermark = () => <Image src={logo} style={styles.watermark} />;
// DeliverySlip component
const DeliverySlip = ({ data }) => {
  const [ProductData, setProductData] = useState([]);
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/product.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "listProduct", // Include the action parameter
            search_text: "", // Optional parameter for filtering
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        console.log(responseData); // Logging the entire response data for debugging

        if (
          responseData.head.code === 200 &&
          responseData.head.msg === "Success"
        ) {
          let sortedData = responseData.body.products; // Adjusted to match the response format

          setProductData(sortedData);
        } else {
          throw new Error(
            responseData.head.msg ? responseData.head.msg : "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);
  let totalcase = 0;
  // Parse and prepare product data
  const products = JSON.parse(data?.products || "[]").map((product, index) => {
    const selectedProduct = ProductData.find(
      (productjson) => productjson.product_name === product.productName
    );

    totalcase += parseFloat(product.quantity) || 0;

    return {
      sNo: index + 1,
      name: product.productName || "Unknown Product",
      unitQty: product.quantity || 0,
      subUnitQty: selectedProduct?.Sub_count || 0,
      unitLabel: selectedProduct?.Unit_type || "",
      subUnitLabel: selectedProduct?.SubUnit_type || "",
      total:
        product.quantity * selectedProduct?.Sub_count +
          "(" +
          selectedProduct?.SubUnit_type +
          ")" || 0, // Adjust calculation if needed
    };
  });
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.section}>
          <Watermark />
          {/* Header */}
          <Text style={styles.title}>Delivery Slip</Text>
          {/* Address and other details */}
          <View style={styles.address}>
            <Text style={styles.godTitle}>
              ஸ்ரீ அங்காளஈஸ்வரி வாலக்குருநாதர் துணை
            </Text>
            <Text style={[styles.companyName, styles.boldText]}>
              {"MAHAVILVAM PYROTECH"}
            </Text>
            <View style={styles.CompanyAddress}>
              <Text>
                {"3/1256/6, Mahalakshmi Nagar, Paraipatti, Sivakasi-626128"}
              </Text>
            </View>
          </View>
          {/* Date and Number */}
          <View style={styles.billRow}>
            <View style={styles.billCol}>
              <Text style={styles.billCell}>No: {data?.delivery_silp_no}</Text>
            </View>
            <View style={styles.billCol}>
              <Text style={[styles.billCell, styles.textCenter]}>
                Date: {formatDate(data?.create_at)}
              </Text>
            </View>
          </View>
          <View style={styles.billRow}>
            <View style={styles.billCol}>
              <Text style={styles.billCell}>To : {data?.customer_name}</Text>
            </View>
            <View style={styles.billCol}>
              <Text style={[styles.billCell, styles.textCenter]}>
                place : {data?.place !== "" ? data?.place : data.address}
              </Text>
            </View>
          </View>
          <View style={styles.productTable}>
            <View style={[styles.productTableRow, styles.borderBottom]}>
              <Text
                style={[
                  styles.tableHeaderCell,
                  styles.sNo,
                  styles.borderRight,
                  styles.padTop,
                ]}
              >
                S.No
              </Text>
              <Text
                style={[
                  styles.tableHeaderCell,
                  styles.product,
                  styles.borderRight,
                  styles.padTop,
                ]}
              >
                Product Name
              </Text>
              <View style={[styles.tableHeaderCell, styles.qty]}>
                <Text
                  style={[
                    styles.tableHeaderSubCell,
                    styles.textCenter,
                    styles.borderBottom,
                  ]}
                >
                  Qty
                </Text>
                <View style={[styles.productTableRow, styles.textCenter]}>
                  <Text
                    style={[
                      styles.tableHeaderSubCell,
                      styles.mainQty,
                      styles.textCenter,
                      styles.borderRight,
                    ]}
                  >
                    {products[0].unitLabel}
                  </Text>
                  <Text
                    style={[
                      styles.tableHeaderSubCell,
                      styles.textCenter,
                      styles.subQty,
                    ]}
                  >
                    {products[0].subUnitLabel}
                  </Text>
                </View>
              </View>
              <Text
                style={[
                  styles.tableHeaderCell,
                  styles.textCenter,
                  styles.total,
                  styles.padTop,
                ]}
              >
                Total
              </Text>
            </View>
            {/* Add table rows here */}
            {/* Product Table Rows */}
            {products.map((product, index) => (
              <View
                key={index}
                style={[styles.productTableRow, styles.borderBottom]}
              >
                <Text
                  style={[styles.tableCell, styles.sNo, styles.borderRight]}
                >
                  {product.sNo}
                </Text>
                <Text
                  style={[styles.tableCell, styles.product, styles.borderRight]}
                >
                  {product.name}
                </Text>
                <View style={[styles.tableCell, styles.qty]}>
                  <View style={[styles.productTableRow]}>
                    <Text
                      style={[
                        styles.tableSubCell,
                        styles.mainQtyCount,
                        styles.borderRight,
                      ]}
                    >
                      {product.unitQty}
                    </Text>
                    <Text style={[styles.tableSubCell, styles.subQtycount]}>
                      {product.subUnitQty}
                    </Text>
                  </View>
                </View>
                <Text style={[styles.tableCell, styles.total]}>
                  {product.total}
                </Text>
              </View>
            ))}
          </View>
          {/* Footer */}
          <Text style={styles.totalCaseRow}>
            TOTAL CASE - {parseInt(totalcase)}
          </Text>

          <Footer />
        </View>
      </Page>
    </Document>
  );
};

export default DeliverySlip;
